import React from "react";
import { Button, Tag, Modal } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Loading from "../../../Components/Loading";
import { execGetTestimonial, execDeleteTestimonial, execUpdateTestimonial } from "../../../api/apiCalls";
import { getRoleColor } from "../../../config";
import { notifySuccess, notifyError } from "../../../Helpers/notification";
import AddTestimonial from "./AddTestimonial";
import EditTestimonial from "./EditTestimonial";
import EditTestimonialTitle from "./EditTestimonialTitle";
import EditTestimonialRight from "./EditTestimonialRight";
import EditSlidingTextTitle from "./EditSlidingTextTitle";

export default function Testimonial() {
  const [showEdit, setShowEdit] = React.useState(false);
  const [editData, setEditData] = React.useState({});

  const [showAdd, setShowAdd] = React.useState(false);
  const [showEditTile, setShowEditTitle] = React.useState(false);
  const [showEditTileR, setShowEditTitleR] = React.useState(false);
  const [showEditSlidingText, setShowEditSlidingText] = React.useState(false);

  const [state, setState] = React.useState({
    loading: true,
    data: null,
    error: false,
  });

  const fetchUsers = async () => {
    setState({ ...state, data: null, loading: true });
    try {
      const res = await execGetTestimonial();
      console.log(res);
      setState({ loading: false, data: res, error: false });
    } catch (err) {
      console.log(err);
      setState({ loading: false, data: null, error: err });
    }
  };

  const onEdit = (item) => {
    setEditData(item);
    setShowEdit(true);
  };

  React.useEffect(() => {
    fetchUsers();
    //eslint-disable-next-line
  }, []);

  return (
    <section id="contents">
      <AddTestimonial
        visible={showAdd}
        onCancel={(refresh = false) => {
          setShowAdd(false);
          if (refresh === true) fetchUsers();
        }}
      />
      <EditTestimonial
        visible={showEdit}
        data={editData}
        onCancel={(refresh = false) => {
          setShowEdit(false);
          if (refresh === true) fetchUsers();
        }}
      />
      <EditTestimonialTitle
        visible={showEditTile}
        title={(state.data && state.data.title) || ""}
        onCancel={(refresh = false) => {
          setShowEditTitle(false);
          if (refresh === true) fetchUsers();
        }}
      />
      <EditTestimonialRight
        visible={showEditTileR}
        title={(state.data && state.data.titleR) || ""}
        onCancel={(refresh = false) => {
          setShowEditTitleR(false);
          if (refresh === true) fetchUsers();
        }}
      />
      <EditSlidingTextTitle
        visible={showEditSlidingText}
        title={(state.data && state.data.slideText) || ""}
        onCancel={(refresh = false) => {
          setShowEditSlidingText(false);
          if (refresh === true) fetchUsers();
        }}
      />
      {state.data && (
        <React.Fragment>
          <br />
          <div className="flex ci">
            <p>
              <b>Testimonials Title: </b>
            </p>
            <div className="hgap"></div>
            <p>{state.data.title}</p>
            <div className="hgap"></div>
            <Button type="primary" onClick={() => setShowEditTitle(true)} icon={<EditOutlined />} shape="circle" size="small" />
          </div>
          <div className="flex ci">
            <p>
              <b>Testimonials Title Right: </b>
            </p>
            <div className="hgap"></div>
            <p>{state.data.titleR}</p>
            <div className="hgap"></div>
            <Button type="primary" onClick={() => setShowEditTitleR(true)} icon={<EditOutlined />} shape="circle" size="small" />
          </div>
          <div className="flex ci">
            <p>
              <b>Sliding Text: </b>
            </p>
            <div className="hgap"></div>
            <p>{state.data.slideText || "n/a"}</p>
            <div className="hgap"></div>
            <Button type="primary" onClick={() => setShowEditSlidingText(true)} icon={<EditOutlined />} shape="circle" size="small" />
          </div>
        </React.Fragment>
      )}
      <br />
      <div className="flex jcsb">
        <h1 className="title"> Testimonials </h1>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowAdd(true)}>
          Add Testimonial
        </Button>
      </div>
      <br />
      {state.loading && <Loading />}
      {state.error && <p style={{ color: "red" }}>{JSON.stringify(state.error)}</p>}
      {state.data && state.data.data.length > 0 && (
        <React.Fragment>
          <div className="contents-table">
            <table cellPadding="0" cellSpacing="0">
              <ContentTableHead />
              <tbody>
                {state.data.data.map((item, index) => {
                  return <ContentTableItems refresh={fetchUsers} onEdit={() => onEdit(item)} key={item._id} {...item} sn={index + 1} />;
                })}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </section>
  );
}

const ContentTableHead = () => {
  return (
    <thead>
      <tr>
        <th style={{ maxWidth: "40px" }}>SN</th>
        <th>Name</th>
        <th>Work As</th>
        <th>Display Side</th>
        <th>Status</th>
        <th style={{ maxWidth: "200px" }}>Created At</th>
        <th style={{ maxWidth: "200px" }}>Actions</th>
      </tr>
    </thead>
  );
};

const ContentTableItems = (props) => {
  const onDelete = () => {
    Modal.confirm({
      title: "Are you sure?",
      content: "you want to delete this testimonial",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await execDeleteTestimonial(props._id);
          notifySuccess(res.message);
          props.refresh();
        } catch (err) {
          notifyError(err.message);
        }
      },
    });
  };

  const onStatusChange = () => {
    const status = props.status === "Pending" ? "Active" : "Pending";

    Modal.confirm({
      title: "Are you sure?",
      content: "you want to change status to " + status,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await execUpdateTestimonial(props._id, { status });
          notifySuccess(res.message);
          props.refresh();
        } catch (err) {
          notifyError(err.message);
        }
      },
    });
  };

  return (
    <tr>
      <td>{props.sn}</td>
      <td>
        <div className="flex jcc ci">
          <img src={props.image} style={{ width: 52, height: 52, borderRadius: 52, marginRight: 10, objectFit: "cover" }} alt="" />
          <p>{props.name}</p>
        </div>
      </td>
      <td>{props.work_as}</td>
      <td>{props.side}</td>
      <td>
        <Tag color={getRoleColor(props.status)}>{props.status}</Tag>
        <Button shape="circle" onClick={onStatusChange} icon={<EditOutlined />} size="small" type="primary" />
      </td>
      <td>{props.created_at}</td>
      <td className="actions">
        <div className="flex jcc ci">
          <Button shape="circle" onClick={() => props.onEdit()} icon={<EditOutlined />} size="small" type="primary" />
          <div className="hgap"></div>
          <Button shape="circle" onClick={() => onDelete()} icon={<DeleteOutlined />} size="small" type="danger" />
        </div>
      </td>
    </tr>
  );
};
