const universityList = [
  "AI Miami International University of Art and Design",
  "ASA College",
  "ATA Career Education",
  "ATA College",
  "ATA College Tri-County",
  "Aaniiih Nakoda College",
  "Abilene Christian University",
  "Abraham Baldwin Agricultural College",
  "Abraham Lincoln University",
  "Academy College",
  "Academy for Nursing and Health Occupations",
  "Academy of Art University",
  "Academy of Interactive Entertainment",
  "Academy of Natural Therapy Inc",
  "Acupuncture and Massage College",
  "Adams State University",
  "Adelphi University",
  "Adirondack Community College",
  "Adrian College",
  "Advance Science International College",
  "Advanced College",
  "Advanced College-Stockton",
  "Advanced Technology Institute",
  "Advanced Training Associates",
  "AdventHealth University",
  "Advertising Art Educational Services DBA School of Advertising Art",
  "Agnes Scott College",
  "Agriculture and Forestry University",
  "Aiken Technical College",
  "Aims Community College",
  "Alabama A & M University",
  "Alabama State University",
  "Alamance Community College",
  "Alaska Bible College",
  "Alaska Career College",
  "Alaska Christian College",
  "Alaska Pacific University",
  "Albany College of Pharmacy and Health Sciences",
  "Albany State University",
  "Albany Technical College",
  "Albertus Magnus College",
  "Albion College",
  "Albright College",
  "Alcorn State University",
  "Alderson Broaddus University",
  "Alexandria Technical & Community College",
  "Alfred University",
  "Alice Lloyd College",
  "All-State Career School-Allied Health Campus",
  "Allan Hancock College",
  "Allegany College of Maryland",
  "Allegheny College",
  "Allegheny Wesleyan College",
  "Allen College",
  "Allen County Community College",
  "Allen University",
  "Alliant International University-San Diego",
  "Alma College",
  "Alpena Community College",
  "Altierus Career College-Bissonnet",
  "Altierus Career College-Norcross",
  "Altierus Career College-Tampa",
  "Alvernia University",
  "Alverno College",
  "Alvin Community College",
  "Amarillo College",
  "Ambria College of Nursing",
  "AmeriTech College-Draper",
  "America Evangelical University",
  "American Academy McAllister Institute of Funeral Service",
  "American Academy of Art",
  "American Academy of Dramatic Arts-Los Angeles",
  "American Academy of Dramatic Arts-New York",
  "American Baptist College",
  "American Business and Technology University",
  "American Career College-Anaheim",
  "American Career College-Los Angeles",
  "American Career College-Ontario",
  "American College for Medical Careers",
  "American College of Healthcare Sciences",
  "American Conservatory Theater",
  "American Institute of Alternative Medicine",
  "American InterContinental University",
  "American InterContinental University-Atlanta",
  "American InterContinental University-Houston",
  "American International College",
  "American Jewish University",
  "American Medical Academy",
  "American Medical Sciences Center",
  "American Musical and Dramatic Academy",
  "American National University",
  "American National University-Dayton",
  "American National University-Lexington",
  "American National University-Youngstown",
  "American Public University System",
  "American River College",
  "American Sentinel University",
  "American Trade School",
  "American University",
  "American University of Health Sciences",
  "Amherst College",
  "Amridge University",
  "Ancilla College",
  "Anderson University",
  "Anderson University",
  "Andrew College",
  "Andrews University",
  "Angeles College",
  "Angelina College",
  "Angelo State University",
  "Anna Maria College",
  "Anne Arundel Community College",
  "Anoka Technical College",
  "Anoka-Ramsey Community College",
  "Antelope Valley College",
  "Antioch College",
  "Antonelli College-Cincinnati",
  "Antonelli College-Hattiesburg",
  "Antonelli College-Jackson",
  "Apex School of Theology",
  "Appalachian Bible College",
  "Appalachian State University",
  "Aquinas College",
  "Arapahoe Community College",
  "Arcadia University",
  "Argosy University-Atlanta",
  "Argosy University-Chicago",
  "Argosy University-Hawaii",
  "Argosy University-Los Angeles",
  "Argosy University-Northern Virginia",
  "Argosy University-Orange County",
  "Argosy University-Phoenix",
  "Argosy University-Phoenix Online Division",
  "Argosy University-Tampa",
  "Argosy University-The Art Institute of California-Hollywood",
  "Argosy University-The Art Institute of California-San Diego",
  "Argosy University-Twin Cities",
  "Arizona Christian University",
  "Arizona College-Glendale",
  "Arizona College-Las Vegas",
  "Arizona College-Mesa",
  "Arizona State University-Downtown Phoenix",
  "Arizona State University-Polytechnic",
  "Arizona State University-Skysong",
  "Arizona State University-Tempe",
  "Arizona State University-West",
  "Arizona Western College",
  "Arkansas Baptist College",
  "Arkansas Northeastern College",
  "Arkansas State University Mid-South",
  "Arkansas State University-Beebe",
  "Arkansas State University-Main Campus",
  "Arkansas State University-Mountain Home",
  "Arkansas State University-Newport",
  "Arkansas Tech University",
  "Arlington Baptist University",
  "Art Academy of Cincinnati",
  "Art Center College of Design",
  "Asbury University",
  "Asher College",
  "Asheville-Buncombe Technical Community College",
  "Ashford University",
  "Ashland Community and Technical College",
  "Ashland University",
  "Asnuntuck Community College",
  "Aspen University",
  "Associated Beth Rivkah Schools",
  "Assumption College",
  "Assumption College for Sisters",
  "Athena Career Academy",
  "Athens Technical College",
  "Atlanta Institute of Music and Media",
  "Atlanta Metropolitan State College",
  "Atlanta Technical College",
  "Atlantic Cape Community College",
  "Atlantis University",
  "Auburn University",
  "Auburn University at Montgomery",
  "Augsburg University",
  "Augusta Technical College",
  "Augusta University",
  "Augustana College",
  "Augustana University",
  "Auguste Escoffier School of Culinary Arts-Austin",
  "Auguste Escoffier School of Culinary Arts-Boulder",
  "Aultman College of Nursing and Health Sciences",
  "Aurora University",
  "Austin College",
  "Austin Community College District",
  "Austin Peay State University",
  "Ave Maria University",
  "Averett University",
  "Averett University-Non-Traditional Programs",
  "Aviation Institute of Maintenance-Atlanta",
  "Aviator College of Aeronautical Science and Technology",
  "Avila University",
  "Azusa Pacific University",
  "Babson College",
  "Bacone College",
  "Bais Binyomin Academy",
  "Bais HaMedrash and Mesivta of Baltimore",
  "Bais Medrash Elyon",
  "Bais Medrash Mayan Hatorah",
  "Bais Medrash Toras Chesed",
  "Baker College",
  "Baker University",
  "Bakersfield College",
  "Baldwin Wallace University",
  "Ball State University",
  "Baltimore City Community College",
  "Baptist Bible College",
  "Baptist Health College Little Rock",
  "Baptist Memorial College of Health Sciences",
  "Baptist Missionary Association Theological Seminary",
  "Baptist University of the Americas",
  "Barclay College",
  "Bard College",
  "Bard College at Simon's Rock",
  "Barnard College",
  "Barry University",
  "Barstow Community College",
  "Barton College",
  "Barton County Community College",
  "Bates College",
  "Bates Technical College",
  "Baton Rouge Community College",
  "Baton Rouge School of Computers",
  "Bay Atlantic University",
  "Bay Mills Community College",
  "Bay Path University",
  "Bay State College",
  "Bay de Noc Community College",
  "Baylor University",
  "Be'er Yaakov Talmudic Seminary",
  "Beacon College",
  "Beal College",
  "Beaufort County Community College",
  "Becker College",
  "Beckfield College-Florence",
  "Beis Medrash Heichal Dovid",
  "Bel-Rea Institute of Animal Technology",
  "Belanger School of Nursing",
  "Belhaven University",
  "Bellarmine University",
  "Bellevue College",
  "Bellevue University",
  "Bellin College",
  "Bellingham Technical College",
  "Belmont Abbey College",
  "Belmont College",
  "Belmont University",
  "Beloit College",
  "Bemidji State University",
  "Benedict College",
  "Benedictine College",
  "Benedictine University",
  "Benjamin Franklin Institute of Technology",
  "Bennett College",
  "Bennington College",
  "Bentley University",
  "Berea College",
  "Bergen Community College",
  "Berkeley City College",
  "Berkeley College-New York",
  "Berkeley College-Woodland Park",
  "Berklee College of Music",
  "Berkshire Community College",
  "Berry College",
  "Best Care College",
  "Bet Medrash Gadol Ateret Torah",
  "Beth Hamedrash Shaarei Yosher Institute",
  "Beth Hatalmud Rabbinical College",
  "Beth Medrash Meor Yitzchok",
  "Beth Medrash of Asbury Park",
  "Bethany College",
  "Bethany College",
  "Bethany Global University",
  "Bethany Lutheran College",
  "Bethel College",
  "Bethel College-Indiana",
  "Bethel College-North Newton",
  "Bethel University",
  "Bethel University",
  "Bethesda University",
  "Bethlehem College & Seminary",
  "Bethune-Cookman University",
  "Beulah Heights University",
  "Beverly Hills Design Institute",
  "Bevill State Community College",
  "Bidwell Training Center Inc",
  "Big Bend Community College",
  "Big Sandy Community and Technical College",
  "Binghamton University",
  "Biola University",
  "Birmingham Southern College",
  "Bishop State Community College",
  "Bismarck State College",
  "Black Hawk College",
  "Black Hills State University",
  "Black River Technical College",
  "Blackburn College",
  "Blackfeet Community College",
  "Blackhawk Technical College",
  "Bladen Community College",
  "Blinn College",
  "Bloomfield College",
  "Bloomsburg University of Pennsylvania",
  "Blue Cliff College-Alexandria",
  "Blue Cliff College-Metairie",
  "Blue Mountain College",
  "Blue Mountain Community College",
  "Blue Ridge Community College",
  "Blue Ridge Community College",
  "Blue Ridge Community and Technical College",
  "Bluefield College",
  "Bluefield State College",
  "Bluegrass Community and Technical College",
  "Bluffton University",
  "Bnos Zion Of Bobov Seminary",
  "Bob Jones University",
  "Boise Bible College",
  "Boise State University",
  "Bolivar Technical College",
  "Boricua College",
  "Bossier Parish Community College",
  "Boston Architectural College",
  "Boston Baptist College",
  "Boston College",
  "Boston University",
  "Bowdoin College",
  "Bowie State University",
  "Bowling Green State University-Firelands",
  "Bowling Green State University-Main Campus",
  "Bradford School",
  "Bradford School",
  "Bradley University",
  "Brandeis University",
  "Brandman University",
  "Brazosport College",
  "Brenau University",
  "Brescia University",
  "Brevard College",
  "Brewton-Parker College",
  "Briar Cliff University",
  "BridgeValley Community & Technical College",
  "Bridgewater College",
  "Bridgewater State University",
  "Brigham Young University-Hawaii",
  "Brigham Young University-Idaho",
  "Brigham Young University-Provo",
  "Brightwood Career Institute-Broomall",
  "Brightwood Career Institute-Harrisburg",
  "Brightwood Career Institute-Philadelphia Mills",
  "Brightwood College-Beltsville",
  "Brightwood College-Brownsville",
  "Brightwood College-Chula Vista",
  "Brightwood College-Corpus Christi",
  "Brightwood College-Dallas",
  "Brightwood College-El Paso",
  "Brightwood College-Fort Worth",
  "Brightwood College-Friendswood",
  "Brightwood College-Houston",
  "Brightwood College-Indianapolis",
  "Brightwood College-Laredo",
  "Brightwood College-Las Vegas",
  "Brightwood College-Los Angeles-Van Nuys",
  "Brightwood College-McAllen",
  "Brightwood College-Modesto",
  "Brightwood College-Nashville",
  "Brightwood College-Riverside",
  "Brightwood College-San Antonio-Ingram",
  "Brightwood College-San Antonio-San Pedro",
  "Brightwood College-San Diego",
  "Brightwood College-Towson",
  "Brightwood College-Vista",
  "Bristol Community College",
  "Broadview Entertainment Arts University",
  "Broadview University-West Jordan",
  "Brookdale Community College",
  "Brookhaven College",
  "Brookline College-Albuquerque",
  "Brookline College-Phoenix",
  "Brookline College-Tempe",
  "Brookline College-Tucson",
  "Broward College",
  "Brown College of Court Reporting",
  "Brown University",
  "Brunswick Community College",
  "Bryan College of Health Sciences",
  "Bryan College-Dayton",
  "Bryan University",
  "Bryan University",
  "Bryan University",
  "Bryant & Stratton College-Akron",
  "Bryant & Stratton College-Albany",
  "Bryant & Stratton College-Amherst",
  "Bryant & Stratton College-Bayshore",
  "Bryant & Stratton College-Buffalo",
  "Bryant & Stratton College-Cleveland",
  "Bryant & Stratton College-Greece",
  "Bryant & Stratton College-Hampton",
  "Bryant & Stratton College-Henrietta",
  "Bryant & Stratton College-Milwaukee",
  "Bryant & Stratton College-Online",
  "Bryant & Stratton College-Parma",
  "Bryant & Stratton College-Racine",
  "Bryant & Stratton College-Richmond",
  "Bryant & Stratton College-Solon",
  "Bryant & Stratton College-Southtowns",
  "Bryant & Stratton College-Syracuse",
  "Bryant & Stratton College-Syracuse North",
  "Bryant & Stratton College-Virginia Beach",
  "Bryant & Stratton College-Wauwatosa",
  "Bryant University",
  "Bryn Athyn College of the New Church",
  "Bryn Mawr College",
  "Bucknell University",
  "Bucks County Community College",
  "Buena Vista University",
  "Bunker Hill Community College",
  "Butler Community College",
  "Butler County Community College",
  "Butler University",
  "Butte College",
  "CBD College",
  "CUNY Bernard M Baruch College",
  "CUNY Borough of Manhattan Community College",
  "CUNY Bronx Community College",
  "CUNY Brooklyn College",
  "CUNY City College",
  "CUNY Hostos Community College",
  "CUNY Hunter College",
  "CUNY John Jay College of Criminal Justice",
  "CUNY Kingsborough Community College",
  "CUNY LaGuardia Community College",
  "CUNY Lehman College",
  "CUNY Medgar Evers College",
  "CUNY New York City College of Technology",
  "CUNY Queens College",
  "CUNY Queensborough Community College",
  "CUNY York College",
  "Cabarrus College of Health Sciences",
  "Cabrillo College",
  "Cabrini University",
  "Cairn University-Langhorne",
  "Caldwell Community College and Technical Institute",
  "Caldwell University",
  "California Aeronautical University",
  "California Baptist University",
  "California Christian College",
  "California Coast University",
  "California College San Diego",
  "California College San Diego",
  "California College of the Arts",
  "California Institute of Arts & Technology",
  "California Institute of Technology",
  "California Institute of the Arts",
  "California Intercontinental University",
  "California Jazz Conservatory",
  "California Lutheran University",
  "California Miramar University",
  "California Polytechnic State University-San Luis Obispo",
  "California State Polytechnic University-Pomona",
  "California State University Maritime Academy",
  "California State University-Bakersfield",
  "California State University-Channel Islands",
  "California State University-Chico",
  "California State University-Dominguez Hills",
  "California State University-East Bay",
  "California State University-Fresno",
  "California State University-Fullerton",
  "California State University-Long Beach",
  "California State University-Los Angeles",
  "California State University-Monterey Bay",
  "California State University-Northridge",
  "California State University-Sacramento",
  "California State University-San Bernardino",
  "California State University-San Marcos",
  "California State University-Stanislaus",
  "California University of Management and Sciences",
  "California University of Pennsylvania",
  "Calumet College of Saint Joseph",
  "Calvary University",
  "Calvin College",
  "Cambridge College",
  "Cambridge College of Healthcare & Technology",
  "Cambridge Institute of Allied Health & Technology",
  "Camden County College",
  "Cameron University",
  "Campbell University",
  "Campbellsville University",
  "Canada College",
  "Canisius College",
  "Cankdeska Cikana Community College",
  "Cape Cod Community College",
  "Cape Fear Community College",
  "Capella University",
  "Capital Community College",
  "Capital University",
  "Capitol Technology University",
  "Cardinal Stritch University",
  "Career Care Institute",
  "Career College of Northern Nevada",
  "Career Networks Institute",
  "Career Quest Learning Center-Bay City Branch",
  "Career Quest Learning Center-Mt. Pleasant",
  "Career Quest Learning Centers-Jackson",
  "Career Quest Learning Centers-Lansing",
  "Career Technical Institute",
  "Career Training Academy-Lower Burrell",
  "Career Training Academy-Pittsburgh",
  "Caris College",
  "Carl Albert State College",
  "Carl Sandburg College",
  "Carleton College",
  "Carlos Albizu University-Miami",
  "Carlow University",
  "Carnegie Mellon University",
  "Carolina Christian College",
  "Carolina College of Biblical Studies",
  "Carolinas College of Health Sciences",
  "Carrington College-Albuquerque",
  "Carrington College-Boise",
  "Carrington College-Citrus Heights",
  "Carrington College-Las Vegas",
  "Carrington College-Mesa",
  "Carrington College-Mesquite",
  "Carrington College-Phoenix East",
  "Carrington College-Phoenix North",
  "Carrington College-Pleasant Hill",
  "Carrington College-Pomona",
  "Carrington College-Reno",
  "Carrington College-Sacramento",
  "Carrington College-San Jose",
  "Carrington College-San Leandro",
  "Carrington College-Spokane",
  "Carrington College-Stockton",
  "Carrington College-Tucson",
  "Carroll College",
  "Carroll Community College",
  "Carroll University",
  "Carson-Newman University",
  "Carteret Community College",
  "Carthage College",
  "Carver Bible College",
  "Carver Career Center",
  "Casa Loma College-Van Nuys",
  "Cascadia College",
  "Case Western Reserve University",
  "Casper College",
  "Castleton University",
  "Catawba College",
  "Catawba Valley Community College",
  "Catholic Distance University",
  "Catholic University of America",
  "Cayuga County Community College",
  "Cazenovia College",
  "Cecil College",
  "Cedar Crest College",
  "Cedar Valley College",
  "Cedarville University",
  "Centenary College of Louisiana",
  "Centenary University",
  "Center for Advanced Legal Studies",
  "CenterPoint Massage and Shiatsu Therapy School and Clinic",
  "Centra College of Nursing",
  "Central Alabama Community College",
  "Central Arizona College",
  "Central Baptist College",
  "Central Carolina Community College",
  "Central Carolina Technical College",
  "Central Christian College of Kansas",
  "Central Christian College of the Bible",
  "Central Coast College",
  "Central College",
  "Central Community College",
  "Central Connecticut State University",
  "Central Georgia Technical College",
  "Central Lakes College-Brainerd",
  "Central Louisiana Technical Community College",
  "Central Maine Community College",
  "Central Methodist University-College of Graduate and Extended Studies",
  "Central Methodist University-College of Liberal Arts and Sciences",
  "Central Michigan University",
  "Central New Mexico Community College",
  "Central Ohio Technical College",
  "Central Oregon Community College",
  "Central Penn College",
  "Central Pennsylvania Institute of Science and Technology",
  "Central Piedmont Community College",
  "Central State University",
  "Central Texas College",
  "Central Virginia Community College",
  "Central Washington University",
  "Central Wyoming College",
  "Central Yeshiva Tomchei Tmimim Lubavitz",
  "Centralia College",
  "Centre College",
  "Centura College-Chesapeake",
  "Centura College-Columbia",
  "Centura College-Newport News",
  "Centura College-Norfolk",
  "Centura College-Richmond Main",
  "Centura College-Virginia Beach",
  "Century College",
  "Cerritos College",
  "Cerro Coso Community College",
  "Chabot College",
  "Chadron State College",
  "Chaffey College",
  "Chamberlain University-Arizona",
  "Chamberlain University-California",
  "Chamberlain University-Florida",
  "Chamberlain University-Georgia",
  "Chamberlain University-Illinois",
  "Chamberlain University-Indiana",
  "Chamberlain University-Louisiana",
  "Chamberlain University-Michigan",
  "Chamberlain University-Missouri",
  "Chamberlain University-Nevada",
  "Chamberlain University-New Jersey",
  "Chamberlain University-North Carolina",
  "Chamberlain University-Ohio",
  "Chamberlain University-Texas",
  "Chamberlain University-Virginia",
  "Chaminade University of Honolulu",
  "Champion Christian College",
  "Champlain College",
  "Chandler-Gilbert Community College",
  "Chapman University",
  "Charles R Drew University of Medicine and Science",
  "Charleston Southern University",
  "Charlotte Christian College and Theological Seminary",
  "Charter College",
  "Charter Oak State College",
  "Chatfield College",
  "Chatham University",
  "Chattahoochee Technical College",
  "Chattahoochee Valley Community College",
  "Chattanooga College Medical Dental and Technical Careers",
  "Chattanooga State Community College",
  "Chemeketa Community College",
  "Chesapeake College",
  "Chester Career College",
  "Chestnut Hill College",
  "Cheyney University of Pennsylvania",
  "Chicago State University",
  "Chief Dull Knife College",
  "Chipola College",
  "Chippewa Valley Technical College",
  "Chowan University",
  "Christian Brothers University",
  "Christian Life College",
  "Christopher Newport University",
  "Cincinnati Christian University",
  "Cincinnati State Technical and Community College",
  "Cisco College",
  "Citadel Military College of South Carolina",
  "Citrus College",
  "City College of San Francisco",
  "City College-Altamonte Springs",
  "City College-Fort Lauderdale",
  "City College-Gainesville",
  "City College-Hollywood",
  "City College-Miami",
  "City Colleges of Chicago-Harold Washington College",
  "City Colleges of Chicago-Harry S Truman College",
  "City Colleges of Chicago-Kennedy-King College",
  "City Colleges of Chicago-Malcolm X College",
  "City Colleges of Chicago-Olive-Harvey College",
  "City Colleges of Chicago-Richard J Daley College",
  "City Colleges of Chicago-Wilbur Wright College",
  "City University of Seattle",
  "City Vision University",
  "Clackamas Community College",
  "Claflin University",
  "Claremont McKenna College",
  "Clarendon College",
  "Clarion University of Pennsylvania",
  "Clark Atlanta University",
  "Clark College",
  "Clark State Community College",
  "Clark University",
  "Clarke University",
  "Clarks Summit University",
  "Clarkson College",
  "Clarkson University",
  "Clatsop Community College",
  "Clayton  State University",
  "Clear Creek Baptist Bible College",
  "Cleary University",
  "Clemson University",
  "Cleveland Community College",
  "Cleveland Institute of Art",
  "Cleveland Institute of Music",
  "Cleveland State Community College",
  "Cleveland State University",
  "Cleveland University-Kansas City",
  "Clinton College",
  "Clinton Community College",
  "Cloud County Community College",
  "Clover Park Technical College",
  "Clovis Community College",
  "Clovis Community College",
  "Coahoma Community College",
  "Coastal Alabama Community College",
  "Coastal Bend College",
  "Coastal Carolina Community College",
  "Coastal Carolina University",
  "Coastal Pines Technical College",
  "Coastline Community College",
  "Cochise County Community College District",
  "Coconino Community College",
  "Coe College",
  "Coffeyville Community College",
  "Cogswell College",
  "Coker College",
  "Colby College",
  "Colby Community College",
  "Colby-Sawyer College",
  "Colgate University",
  "College for Creative Studies",
  "College of Alameda",
  "College of Biblical Studies-Houston",
  "College of Business and Technology-Cutler Bay",
  "College of Business and Technology-Hialeah",
  "College of Business and Technology-Main Campus",
  "College of Business and Technology-Miami Campus",
  "College of Business and Technology-Miami Gardens",
  "College of Central Florida",
  "College of Charleston",
  "College of Coastal Georgia",
  "College of Court Reporting Inc",
  "College of DuPage",
  "College of Eastern Idaho",
  "College of Lake County",
  "College of Marin",
  "College of Menominee Nation",
  "College of Mount Saint Vincent",
  "College of Our Lady of the Elms",
  "College of Saint Benedict",
  "College of Saint Elizabeth",
  "College of Saint Mary",
  "College of San Mateo",
  "College of Southern Idaho",
  "College of Southern Maryland",
  "College of Southern Nevada",
  "College of St Joseph",
  "College of Staten Island CUNY",
  "College of Western Idaho",
  "College of William and Mary",
  "College of the Albemarle",
  "College of the Atlantic",
  "College of the Canyons",
  "College of the Desert",
  "College of the Holy Cross",
  "College of the Mainland",
  "College of the Muscogee Nation",
  "College of the Ouachitas",
  "College of the Ozarks",
  "College of the Redwoods",
  "College of the Sequoias",
  "College of the Siskiyous",
  "CollegeAmerica-Colorado Springs",
  "CollegeAmerica-Denver",
  "CollegeAmerica-Flagstaff",
  "CollegeAmerica-Fort Collins",
  "CollegeAmerica-Phoenix",
  "Collin County Community College District",
  "Colorado Academy of Veterinary Technology",
  "Colorado Christian University",
  "Colorado College",
  "Colorado Mesa University",
  "Colorado Mountain College",
  "Colorado Northwestern Community College",
  "Colorado School of Mines",
  "Colorado School of Trades",
  "Colorado State University-Fort Collins",
  "Colorado State University-Pueblo",
  "Colorado Technical University-Colorado Springs",
  "Columbia Basin College",
  "Columbia College",
  "Columbia College",
  "Columbia College",
  "Columbia College",
  "Columbia College",
  "Columbia College Chicago",
  "Columbia College Hollywood",
  "Columbia Gorge Community College",
  "Columbia International University",
  "Columbia Southern University",
  "Columbia State Community College",
  "Columbia University in the City of New York",
  "Columbia-Greene Community College",
  "Columbus College of Art and Design",
  "Columbus State Community College",
  "Columbus State University",
  "Columbus Technical College",
  "Commonwealth Institute of Funeral Service",
  "Commonwealth Technical Institute",
  "Community Care College",
  "Community Christian College",
  "Community College of Allegheny County",
  "Community College of Aurora",
  "Community College of Baltimore County",
  "Community College of Beaver County",
  "Community College of Denver",
  "Community College of Philadelphia",
  "Community College of Rhode Island",
  "Community College of Vermont",
  "Compass College of Cinematic Arts",
  "Compton College",
  "Conception Seminary College",
  "Concord University",
  "Concorde Career College-Aurora",
  "Concorde Career College-Dallas",
  "Concorde Career College-Garden Grove",
  "Concorde Career College-Grand Prairie",
  "Concorde Career College-Kansas City",
  "Concorde Career College-Memphis",
  "Concorde Career College-North Hollywood",
  "Concorde Career College-Portland",
  "Concorde Career College-San Antonio",
  "Concorde Career College-San Bernardino",
  "Concorde Career College-San Diego",
  "Concorde Career College-Southaven",
  "Concorde Career Institute-Jacksonville",
  "Concorde Career Institute-Miramar",
  "Concorde Career Institute-Orlando",
  "Concorde Career Institute-Tampa",
  "Concordia College at Moorhead",
  "Concordia College-New York",
  "Concordia University Texas",
  "Concordia University-Ann Arbor",
  "Concordia University-Chicago",
  "Concordia University-Irvine",
  "Concordia University-Nebraska",
  "Concordia University-Portland",
  "Concordia University-Saint Paul",
  "Concordia University-Wisconsin",
  "Connecticut College",
  "Connors State College",
  "Contra Costa College",
  "Converse College",
  "Cooper Union for the Advancement of Science and Art",
  "Copiah-Lincoln Community College",
  "Copper Mountain Community College",
  "Coppin State University",
  "Corban University",
  "Cornell College",
  "Cornell University",
  "Cornerstone University",
  "Corning Community College",
  "Cornish College of the Arts",
  "Cortiva Institute-Maitland",
  "Cortiva Institute-Miami",
  "Cortiva Institute-Pompano",
  "Cossatot Community College of the University of Arkansas",
  "Cosumnes River College",
  "Cottey College",
  "County College of Morris",
  "Covenant College",
  "Cowley County Community College",
  "Cox College",
  "Coyne College",
  "Crafton Hills College",
  "Craven Community College",
  "Creighton University",
  "Criswell College",
  "Crossroads Bible College",
  "Crowder College",
  "Crowley's Ridge College",
  "Crown College",
  "Cuesta College",
  "Culinary Institute Inc",
  "Culinary Institute of America",
  "Culver-Stockton College",
  "Cumberland County College",
  "Cumberland University",
  "Curry College",
  "Curtis Institute of Music",
  "Cuyahoga Community College District",
  "Cuyamaca College",
  "Cypress College",
  "D'Youville College",
  "Dabney S Lancaster Community College",
  "Daemen College",
  "Dakota College at Bottineau",
  "Dakota County Technical College",
  "Dakota State University",
  "Dakota Wesleyan University",
  "Dallas Baptist University",
  "Dallas Christian College",
  "Dallas Institute of Funeral Service",
  "Dallas Nursing Institute",
  "Dalton State College",
  "Danville Area Community College",
  "Danville Community College",
  "Dartmouth College",
  "Davenport University",
  "Davidson College",
  "Davidson County Community College",
  "Davis & Elkins College",
  "Davis College",
  "Davis College",
  "Dawson Community College",
  "Daytona College",
  "Daytona State College",
  "De Anza College",
  "DePaul University",
  "DePauw University",
  "DeSales University",
  "DeVry College of New York",
  "DeVry University-Arizona",
  "DeVry University-California",
  "DeVry University-Colorado",
  "DeVry University-Florida",
  "DeVry University-Georgia",
  "DeVry University-Illinois",
  "DeVry University-Indiana",
  "DeVry University-Missouri",
  "DeVry University-Nevada",
  "DeVry University-New Jersey",
  "DeVry University-North Carolina",
  "DeVry University-Ohio",
  "DeVry University-Pennsylvania",
  "DeVry University-Tennessee",
  "DeVry University-Texas",
  "DeVry University-Virginia",
  "Dean College",
  "Dean Institute of Technology",
  "Defiance College",
  "Del Mar College",
  "Delaware College of Art and Design",
  "Delaware County Community College",
  "Delaware State University",
  "Delaware Technical Community College-Terry",
  "Delaware Valley University",
  "Delgado Community College",
  "Dell'Arte International School of Physical Theatre",
  "Delta College",
  "Delta College of Arts & Technology",
  "Delta State University",
  "Denison University",
  "Denmark Technical College",
  "Des Moines Area Community College",
  "Design Institute of San Diego",
  "Diablo Valley College",
  "Dickinson College",
  "Dickinson State University",
  "DigiPen Institute of Technology",
  "Dillard University",
  "Dine College",
  "Divine Word College",
  "Dixie State University",
  "Doane University-Arts & Sciences",
  "Doane University-Graduate and Professional Studies",
  "Dodge City Community College",
  "Dominican College of Blauvelt",
  "Dominican University",
  "Dominican University of California",
  "Donnelly College",
  "Dordt College",
  "Douglas Education Center",
  "Drake University",
  "Drew University",
  "Drexel University",
  "Drury University",
  "Drury University-College of Continuing Professional Studies",
  "Duke University",
  "Dunwoody College of Technology",
  "Duquesne University",
  "Durham Technical Community College",
  "Dutchess Community College",
  "Dyersburg State Community College",
  "ECPI University",
  "ETI Technical College",
  "Eagle Gate College-Layton",
  "Eagle Gate College-Murray",
  "Eagle Rock College",
  "Earlham College",
  "East Arkansas Community College",
  "East Carolina University",
  "East Central College",
  "East Central Community College",
  "East Central University",
  "East Georgia State College",
  "East Los Angeles College",
  "East Mississippi Community College",
  "East San Gabriel Valley Regional Occupational Program",
  "East Stroudsburg University of Pennsylvania",
  "East Tennessee State University",
  "East Texas Baptist University",
  "East-West University",
  "East-West University",
  "Eastern Arizona College",
  "Eastern Connecticut State University",
  "Eastern Florida State College",
  "Eastern Gateway Community College",
  "Eastern Illinois University",
  "Eastern International College-Belleville",
  "Eastern International College-Jersey City",
  "Eastern Iowa Community College District",
  "Eastern Kentucky University",
  "Eastern Maine Community College",
  "Eastern Mennonite University",
  "Eastern Michigan University",
  "Eastern Nazarene College",
  "Eastern New Mexico University Ruidoso Branch Community College",
  "Eastern New Mexico University-Main Campus",
  "Eastern New Mexico University-Roswell Campus",
  "Eastern Oklahoma State College",
  "Eastern Oregon University",
  "Eastern Shore Community College",
  "Eastern University",
  "Eastern Virginia Career College",
  "Eastern Washington University",
  "Eastern West Virginia Community and Technical College",
  "Eastern Wyoming College",
  "Eastfield College",
  "Eastwick College-Hackensack",
  "Eastwick College-Nutley",
  "Eastwick College-Ramsey",
  "Ecclesia College",
  "Eckerd College",
  "Edgecombe Community College",
  "Edgewood College",
  "Edinboro University of Pennsylvania",
  "Edison State Community College",
  "Edmonds Community College",
  "Edward Waters College",
  "El Camino Community College District",
  "El Centro College",
  "El Paso Community College",
  "Elgin Community College",
  "Elim Bible Institute and College",
  "Elizabeth City State University",
  "Elizabethtown College",
  "Elizabethtown Community and Technical College",
  "Ellsworth Community College",
  "Elmhurst College",
  "Elmira Business Institute",
  "Elmira College",
  "Elon University",
  "Elyon College",
  "Embry-Riddle Aeronautical University-Daytona Beach",
  "Embry-Riddle Aeronautical University-Prescott",
  "Embry-Riddle Aeronautical University-Worldwide",
  "Emerson College",
  "Emmanuel College",
  "Emmanuel College",
  "Emmaus Bible College",
  "Emory & Henry College",
  "Emory University",
  "Emory University-Oxford College",
  "Empire College",
  "Emporia State University",
  "Endicott College",
  "Enterprise State Community College",
  "Epic Bible College",
  "Erie Community College",
  "Erie Institute of Technology Inc",
  "Erskine College",
  "Essex County College",
  "Estrella Mountain Community College",
  "Eureka College",
  "Evangel University",
  "Everett Community College",
  "Everglades University",
  "Evergreen Valley College",
  "FIDM-Fashion Institute of Design & Merchandising-Los Angeles",
  "FIDM-Fashion Institute of Design & Merchandising-Orange County",
  "FIDM-Fashion Institute of Design & Merchandising-San Diego",
  "FIDM-Fashion Institute of Design & Merchandising-San Francisco",
  "FINE Mortuary College",
  "Fairfield University",
  "Fairleigh Dickinson University-Florham Campus",
  "Fairleigh Dickinson University-Metropolitan Campus",
  "Fairmont State University",
  "Faith Baptist Bible College and Theological Seminary",
  "Faith International University",
  "Faith Theological Seminary",
  "Family of Faith Christian University",
  "Far Western University",
  "Farmingdale State College",
  "Fashion Institute of Technology",
  "Faulkner University",
  "Fayetteville State University",
  "Fayetteville Technical Community College",
  "Feather River Community College District",
  "Felbry College School of Nursing",
  "Felician University",
  "Ferris State University",
  "Ferrum College",
  "Finger Lakes Community College",
  "Finger Lakes Health College of Nursing",
  "Finlandia University",
  "Fisher College",
  "Fisk University",
  "Fitchburg State University",
  "Five Towns College",
  "Flagler College-St Augustine",
  "Flashpoint Chicago A Campus of Columbia College Hollywood",
  "Flathead Valley Community College",
  "Fletcher Technical Community College",
  "Flint Hills Technical College",
  "Florence-Darlington Technical College",
  "Florida Agricultural and Mechanical University",
  "Florida Atlantic University",
  "Florida Career College",
  "Florida Career College-Boynton Beach",
  "Florida Career College-Hialeah",
  "Florida Career College-Houston Campus",
  "Florida Career College-Jacksonville",
  "Florida Career College-Lauderdale Lakes",
  "Florida Career College-Margate",
  "Florida Career College-Miami",
  "Florida Career College-Orlando",
  "Florida Career College-Tampa",
  "Florida Career College-West Palm Beach",
  "Florida College",
  "Florida Education Institute",
  "Florida Gateway College",
  "Florida Gulf Coast University",
  "Florida Institute of Technology",
  "Florida International University",
  "Florida Keys Community College",
  "Florida Memorial University",
  "Florida National University-Main Campus",
  "Florida Polytechnic University",
  "Florida SouthWestern State College",
  "Florida Southern College",
  "Florida State College at Jacksonville",
  "Florida State University",
  "Florida Technical College",
  "Folsom Lake College",
  "Fond du Lac Tribal and Community College",
  "Fontbonne University",
  "Foothill College",
  "Fordham University",
  "Forrest College",
  "Forsyth Technical Community College",
  "Fort Hays State University",
  "Fort Lewis College",
  "Fort Peck Community College",
  "Fort Scott Community College",
  "Fort Valley State University",
  "Fortis College",
  "Fortis College-Baton Rouge",
  "Fortis College-Centerville",
  "Fortis College-Cincinnati",
  "Fortis College-Columbia",
  "Fortis College-Columbus",
  "Fortis College-Cutler Bay",
  "Fortis College-Cuyahoga Falls",
  "Fortis College-Grand Prairie",
  "Fortis College-Indianapolis",
  "Fortis College-Landover",
  "Fortis College-Montgomery",
  "Fortis College-Norfolk",
  "Fortis College-Orange Park",
  "Fortis College-Richmond",
  "Fortis College-Salt Lake City",
  "Fortis College-Smyrna",
  "Fortis Institute",
  "Fortis Institute-Birmingham",
  "Fortis Institute-Cookeville",
  "Fortis Institute-Erie",
  "Fortis Institute-Forty Fort",
  "Fortis Institute-Nashville",
  "Fortis Institute-Pensacola",
  "Fortis Institute-Port Saint Lucie",
  "Fortis Institute-Scranton",
  "Fountainhead College of Technology",
  "Fox College",
  "Fox Valley Technical College",
  "Framingham State University",
  "Francis Marion University",
  "Franciscan Missionaries of Our Lady University",
  "Franciscan University of Steubenville",
  "Frank Phillips College",
  "Franklin College",
  "Franklin Pierce University",
  "Franklin University",
  "Franklin W Olin College of Engineering",
  "Franklin and Marshall College",
  "Frederick Community College",
  "Freed-Hardeman University",
  "Fremont College",
  "Fresno City College",
  "Fresno Pacific University",
  "Friends University",
  "Front Range Community College",
  "Frontier Community College",
  "Frostburg State University",
  "Full Sail University",
  "Fullerton College",
  "Fulton-Montgomery Community College",
  "Furman University",
  "Future-Tech Institute",
  "Gadsden State Community College",
  "Galen College of Nursing-ARH",
  "Galen College of Nursing-Cincinnati",
  "Galen College of Nursing-Louisville",
  "Galen College of Nursing-San Antonio",
  "Galen College of Nursing-Tampa Bay",
  "Gallaudet University",
  "Galveston College",
  "Gannon University",
  "Garden City Community College",
  "Gardner-Webb University",
  "Garrett College",
  "Gaston College",
  "GateWay Community College",
  "Gateway Community College",
  "Gateway Community and Technical College",
  "Gateway Technical College",
  "Gavilan College",
  "Gemini School of Visual Arts & Communication",
  "Genesee Community College",
  "Geneva College",
  "George C Wallace Community College-Dothan",
  "George C Wallace State Community College-Hanceville",
  "George C Wallace State Community College-Selma",
  "George Fox University",
  "George Mason University",
  "George Washington University",
  "Georgetown College",
  "Georgetown University",
  "Georgia Central University",
  "Georgia College & State University",
  "Georgia Gwinnett College",
  "Georgia Highlands College",
  "Georgia Institute of Technology-Main Campus",
  "Georgia Military College",
  "Georgia Northwestern Technical College",
  "Georgia Piedmont Technical College",
  "Georgia Southern University",
  "Georgia Southwestern State University",
  "Georgia State University",
  "Georgia State University-Perimeter College",
  "Georgian Court University",
  "Germanna Community College",
  "Gettysburg College",
  "Glen Oaks Community College",
  "Glendale Career College",
  "Glendale Community College",
  "Glendale Community College",
  "Glenville State College",
  "Gnomon",
  "Goddard College",
  "Gods Bible School and College",
  "Gogebic Community College",
  "Golden West College",
  "Goldey-Beacom College",
  "Golf Academy of America-Dallas",
  "Golf Academy of America-Myrtle Beach",
  "Golf Academy of America-Orlando",
  "Golf Academy of America-San Diego",
  "Gonzaga University",
  "Good Samaritan College of Nursing and Health Science",
  "Goodwin College",
  "Gordon College",
  "Gordon State College",
  "Goshen College",
  "Goucher College",
  "Governors State University",
  "Grace Christian University",
  "Grace College and Theological Seminary",
  "Grace College of Divinity",
  "Grace Mission University",
  "Grace School of Theology",
  "Graceland University-Lamoni",
  "Grambling State University",
  "Grand Canyon University",
  "Grand Rapids Community College",
  "Grand Valley State University",
  "Grand View University",
  "Granite State College",
  "Grantham University",
  "Grays Harbor College",
  "Grayson College",
  "Great Basin College",
  "Great Bay Community College",
  "Great Falls College Montana State University",
  "Great Lakes Christian College",
  "Great Lakes Institute of Technology",
  "Green Mountain College",
  "Green River College",
  "Greenfield Community College",
  "Greensboro College",
  "Greenville Technical College",
  "Greenville University",
  "Grinnell College",
  "Grossmont College",
  "Grove City College",
  "Guilford College",
  "Guilford Technical Community College",
  "Gulf Coast State College",
  "Gupton Jones College of Funeral Service",
  "Gurnick Academy of Medical Arts",
  "Gustavus Adolphus College",
  "Gwinnett College-Lilburn",
  "Gwinnett College-Marietta Campus",
  "Gwinnett College-Sandy Springs",
  "Gwinnett Institute",
  "Gwinnett Technical College",
  "Gwynedd Mercy University",
  "H Councill Trenholm State Community College",
  "Hacienda La Puente Adult Education",
  "Hagerstown Community College",
  "Halifax Community College",
  "Hallmark University",
  "Hamilton College",
  "Hamilton Technical College",
  "Hamline University",
  "Hampden-Sydney College",
  "Hampshire College",
  "Hampton University",
  "Hannibal-LaGrange University",
  "Hanover College",
  "Harcum College",
  "Hardin-Simmons University",
  "Harding University",
  "Harford Community College",
  "Harris-Stowe State University",
  "Harrisburg Area Community College",
  "Harrisburg University of Science and Technology",
  "Hartnell College",
  "Hartwick College",
  "Harvard University",
  "Harvey Mudd College",
  "Haskell Indian Nations University",
  "Hastings College",
  "Haverford College",
  "Hawaii Community College",
  "Hawaii Medical College",
  "Hawaii Pacific University",
  "Hawkeye Community College",
  "Haywood Community College",
  "Hazard Community and Technical College",
  "Health Career Institute",
  "Healthcare Career College",
  "Heartland Community College",
  "Hebrew Theological College",
  "Heidelberg University",
  "Helena College University of Montana",
  "Hellenic College-Holy Cross Greek Orthodox School of Theology",
  "Helms College",
  "Henderson Community College",
  "Henderson State University",
  "Hendrix College",
  "Hennepin Technical College",
  "Henry Ford College",
  "Heritage Bible College",
  "Heritage Christian University",
  "Heritage University",
  "Herkimer County Community College",
  "Herzing University-Akron",
  "Herzing University-Atlanta",
  "Herzing University-Birmingham",
  "Herzing University-Brookfield",
  "Herzing University-Kenner",
  "Herzing University-Kenosha",
  "Herzing University-Madison",
  "Herzing University-Minneapolis",
  "Herzing University-Toledo",
  "Herzing University-Winter Park",
  "Hesston College",
  "Hibbing Community College",
  "High Desert Medical College",
  "High Point University",
  "Highland Community College",
  "Highland Community College",
  "Highlands College of Montana Tech",
  "Highline College",
  "Hilbert College",
  "Hill College",
  "Hillsborough Community College",
  "Hillsdale College",
  "Hinds Community College",
  "Hiram College",
  "Hiwassee College",
  "Hobart William Smith Colleges",
  "Hobe Sound Bible College",
  "Hocking College",
  "Hodges University",
  "Hofstra University",
  "Hollins University",
  "Holmes Community College",
  "Holy Apostles College and Seminary",
  "Holy Cross College",
  "Holy Family University",
  "Holy Names University",
  "Holyoke Community College",
  "Homestead Schools",
  "Hondros College of Nursing",
  "Honolulu Community College",
  "Hood College",
  "Hope College",
  "Hope College of Arts and Sciences",
  "Hope International University",
  "Hopkinsville Community College",
  "Horizon University",
  "Horry-Georgetown Technical College",
  "Houghton College",
  "Housatonic Community College",
  "Houston Baptist University",
  "Houston Community College",
  "Houston International College Cardiotech Ultrasound School",
  "Howard College",
  "Howard Community College",
  "Howard Payne University",
  "Howard University",
  "Hudson County Community College",
  "Hudson Valley Community College",
  "Hult International Business School",
  "Humboldt State University",
  "Humphreys University-Stockton and Modesto Campuses",
  "Huntingdon College",
  "Huntington Junior College",
  "Huntington University",
  "Huntington University of Health Sciences",
  "Huntsville Bible College",
  "Hussian College School of Art",
  "Hussian College-Daymar College Bowling Green",
  "Hussian College-Daymar College Clarksville",
  "Hussian College-Daymar College Columbus",
  "Hussian College-Daymar College Murfreesboro",
  "Hussian College-Daymar College Nashville",
  "Hussian College-Relativity Campus California",
  "Husson University",
  "Huston-Tillotson University",
  "Hutchinson Community College",
  "IBMC College",
  "IGlobal University",
  "ITI Technical College",
  "Idaho State University",
  "Ilisagvik College",
  "Illinois Central College",
  "Illinois College",
  "Illinois Institute of Technology",
  "Illinois State University",
  "Illinois Valley Community College",
  "Illinois Wesleyan University",
  "Immaculata University",
  "Imperial Valley College",
  "Independence Community College",
  "Independence University",
  "Indian Hills Community College",
  "Indian River State College",
  "Indiana Institute of Technology",
  "Indiana Institute of Technology-College of Professions Studies",
  "Indiana State University",
  "Indiana University of Pennsylvania-Main Campus",
  "Indiana University-Bloomington",
  "Indiana University-East",
  "Indiana University-Kokomo",
  "Indiana University-Northwest",
  "Indiana University-Purdue University-Indianapolis",
  "Indiana University-South Bend",
  "Indiana University-Southeast",
  "Indiana Wesleyan University-Marion",
  "Indiana Wesleyan University-National & Global",
  "Institute for Business and Technology",
  "Institute of American Indian and Alaska Native Culture and Arts Development",
  "Institute of Lutheran Theology",
  "Institute of Medical Careers",
  "Institute of Production and Recording",
  "Institute of Technology",
  "Intellitec College-Colorado Springs",
  "Intellitec College-Grand Junction",
  "InterCoast Colleges-Anaheim",
  "InterCoast Colleges-Fairfield",
  "InterCoast Colleges-Riverside",
  "InterCoast Colleges-West Covina",
  "Interactive College of Technology",
  "Interactive College of Technology",
  "Interactive College of Technology",
  "Interactive College of Technology-Chamblee",
  "Interactive College of Technology-Gainesville",
  "Interactive College of Technology-Morrow",
  "Interactive College of Technology-Newport",
  "Interior Designers Institute",
  "International Baptist College and Seminary",
  "International Business College-El Paso",
  "International Business College-El Paso",
  "International Business College-Fort Wayne",
  "International Business College-Indianapolis",
  "International College of Broadcasting",
  "Inver Hills Community College",
  "Iona College",
  "Iowa Central Community College",
  "Iowa Lakes Community College",
  "Iowa State University",
  "Iowa Wesleyan University",
  "Iowa Western Community College",
  "Irvine Valley College",
  "Island Drafting and Technical Institute",
  "Isothermal Community College",
  "Itasca Community College",
  "Itawamba Community College",
  "Ithaca College",
  "Ivy Tech Community College",
  "J F Ingram State Technical College",
  "J Sargeant Reynolds Community College",
  "J. F. Drake State Community and Technical College",
  "Jackson College",
  "Jackson State Community College",
  "Jackson State University",
  "Jacksonville College-Main Campus",
  "Jacksonville State University",
  "Jacksonville University",
  "James A Rhodes State College",
  "James Madison University",
  "James Sprunt Community College",
  "Jamestown Business College",
  "Jamestown Community College",
  "Jarvis Christian College",
  "Jefferson College",
  "Jefferson College of Health Sciences",
  "Jefferson Community College",
  "Jefferson Community and Technical College",
  "Jefferson State Community College",
  "Jersey College",
  "Jewish Theological Seminary of America",
  "Jna Institute of Culinary Arts",
  "John A Gupton College",
  "John A Logan College",
  "John Brown University",
  "John C Calhoun State Community College",
  "John Carroll University",
  "John F. Kennedy University",
  "John Paul the Great Catholic University",
  "John Tyler Community College",
  "John Wood Community College",
  "Johns Hopkins University",
  "Johnson & Wales University-Charlotte",
  "Johnson & Wales University-Denver",
  "Johnson & Wales University-North Miami",
  "Johnson & Wales University-Online",
  "Johnson & Wales University-Providence",
  "Johnson C Smith University",
  "Johnson College",
  "Johnson County Community College",
  "Johnson University",
  "Johnson University Florida",
  "Johnston Community College",
  "Joliet Junior College",
  "Jones County Junior College",
  "Jose Maria Vargas University",
  "Judson College",
  "Judson University",
  "Juniata College",
  "KD Conservatory College of Film and Dramatic Arts",
  "Kalamazoo College",
  "Kalamazoo Valley Community College",
  "Kankakee Community College",
  "Kansas Christian College",
  "Kansas City Art Institute",
  "Kansas City Kansas Community College",
  "Kansas State University",
  "Kansas Wesleyan University",
  "Kapiolani Community College",
  "Kaskaskia College",
  "Kathmandu University",
  "Kauai Community College",
  "Kean University",
  "Keene State College",
  "Kehilath Yakov Rabbinical Seminary",
  "Keiser University-Ft Lauderdale",
  "Kellogg Community College",
  "Kennebec Valley Community College",
  "Kennesaw State University",
  "Kenrick Glennon Seminary",
  "Kent State University at Ashtabula",
  "Kent State University at East Liverpool",
  "Kent State University at Geauga",
  "Kent State University at Kent",
  "Kent State University at Salem",
  "Kent State University at Stark",
  "Kent State University at Trumbull",
  "Kent State University at Tuscarawas",
  "Kentucky Christian University",
  "Kentucky Mountain Bible College",
  "Kentucky State University",
  "Kentucky Wesleyan College",
  "Kenyon College",
  "Kettering College",
  "Kettering University",
  "Keuka College",
  "Keweenaw Bay Ojibwa Community College",
  "Keystone College",
  "Kilgore College",
  "King University",
  "King's College",
  "King's College",
  "Kirkwood Community College",
  "Kirtland Community College",
  "Kishwaukee College",
  "Klamath Community College",
  "Knox College",
  "Kutztown University of Pennsylvania",
  "Kuyper College",
  "L'Ecole Culinaire-Kansas City",
  "L'Ecole Culinaire-Memphis",
  "L'Ecole Culinaire-St Louis",
  "LDS Business College",
  "LIM College",
  "La Roche University",
  "La Salle University",
  "La Sierra University",
  "LaGrange College",
  "Labette Community College",
  "Laboure College",
  "Lac Courte Oreilles Ojibwa Community College",
  "Lackawanna College",
  "Lafayette College",
  "Laguna College of Art and Design",
  "Lake Area Technical Institute",
  "Lake Erie College",
  "Lake Forest College",
  "Lake Land College",
  "Lake Michigan College",
  "Lake Region State College",
  "Lake Superior College",
  "Lake Superior State University",
  "Lake Tahoe Community College",
  "Lake Washington Institute of Technology",
  "Lake-Sumter State College",
  "Lakeland Community College",
  "Lakeland University",
  "Lakes Region Community College",
  "Lakeshore Technical College",
  "Lamar Community College",
  "Lamar Institute of Technology",
  "Lamar State College-Orange",
  "Lamar State College-Port Arthur",
  "Lamar University",
  "Lancaster Bible College",
  "Lancaster County Career and Technology Center",
  "Lander University",
  "Landmark College",
  "Lane College",
  "Lane Community College",
  "Laney College",
  "Langston University",
  "Lanier Technical College",
  "Lansdale School of Business",
  "Lansing Community College",
  "Laramie County Community College",
  "Laredo College",
  "Las Positas College",
  "Las Vegas College",
  "Lasell College",
  "Lassen Community College",
  "Laurel Business Institute",
  "Laurel Technical Institute",
  "Laurel Technical Institute",
  "Laurus College",
  "Lawrence Memorial Hospital School of Nursing",
  "Lawrence Technological University",
  "Lawrence University",
  "Lawson State Community College",
  "Le Moyne College",
  "Le Moyne-Owen College",
  "LeTourneau University",
  "Learnet Academy Inc",
  "Lebanon Valley College",
  "Lee College",
  "Lee University",
  "Leech Lake Tribal College",
  "Lees-McRae College",
  "Leeward Community College",
  "Lehigh Carbon Community College",
  "Lehigh University",
  "Lenoir Community College",
  "Lenoir-Rhyne University",
  "Lesley University",
  "Lewis & Clark College",
  "Lewis University",
  "Lewis and Clark Community College",
  "Lewis-Clark State College",
  "Liberty University",
  "Life Pacific College",
  "Life University",
  "Limestone College",
  "Lincoln Christian University",
  "Lincoln College",
  "Lincoln College of New England-Southington",
  "Lincoln College of Technology-Columbia",
  "Lincoln College of Technology-Denver",
  "Lincoln College of Technology-Grand Prairie",
  "Lincoln College of Technology-Indianapolis",
  "Lincoln College of Technology-Marietta",
  "Lincoln College of Technology-Melrose Park",
  "Lincoln College of Technology-Nashville",
  "Lincoln Land Community College",
  "Lincoln Memorial University",
  "Lincoln Technical Institute-Allentown",
  "Lincoln Technical Institute-Philadelphia",
  "Lincoln Trail College",
  "Lincoln University",
  "Lincoln University",
  "Lincoln University",
  "Lindenwood University",
  "Lindsey Wilson College",
  "Linfield College-McMinnville Campus",
  "Linn-Benton Community College",
  "Lipscomb University",
  "Little Big Horn College",
  "Little Priest Tribal College",
  "Living Arts College",
  "Livingstone College",
  "Lock Haven University",
  "Logan University",
  "Lone Star College System",
  "Long Beach City College",
  "Long Island Business Institute",
  "Long Island University",
  "Longwood University",
  "Longy School of Music of Bard College",
  "Lorain County Community College",
  "Loras College",
  "Lord Fairfax Community College",
  "Los Angeles Academy of Figurative Art",
  "Los Angeles City College",
  "Los Angeles College of Music",
  "Los Angeles Film School",
  "Los Angeles Harbor College",
  "Los Angeles Mission College",
  "Los Angeles ORT College-Los Angeles Campus",
  "Los Angeles ORT College-Van Nuys Campus",
  "Los Angeles Pacific University",
  "Los Angeles Pierce College",
  "Los Angeles Southwest College",
  "Los Angeles Trade Technical College",
  "Los Angeles Valley College",
  "Los Medanos College",
  "Louisburg College",
  "Louisiana College",
  "Louisiana Culinary Institute",
  "Louisiana Delta Community College",
  "Louisiana State University and Agricultural & Mechanical College",
  "Louisiana State University-Alexandria",
  "Louisiana State University-Eunice",
  "Louisiana State University-Shreveport",
  "Louisiana Tech University",
  "Lourdes University",
  "Lower Columbia College",
  "Loyola Marymount University",
  "Loyola University Chicago",
  "Loyola University Maryland",
  "Loyola University New Orleans",
  "Lubbock Christian University",
  "Lumbini Buddhist University",
  "Luna Community College",
  "Lurleen B Wallace Community College",
  "Luther College",
  "Luther Rice College & Seminary",
  "Luzerne County Community College",
  "Lycoming College",
  "Lynn University",
  "Lyon College",
  "MCPHS University",
  "MIAT College of Technology",
  "MTI College",
  "MacCormac College",
  "MacMurray College",
  "Macalester College",
  "Machzikei Hadath Rabbinical College",
  "Macomb Community College",
  "Madison Area Technical College",
  "Madison Media Institute",
  "Madison Media Institute-Rockford Career College",
  "Madisonville Community College",
  "Madonna University",
  "Maharishi University of Management",
  "Maine College of Art",
  "Maine College of Health Professions",
  "Maine Maritime Academy",
  "Malone University",
  "Manchester Community College",
  "Manchester Community College",
  "Manchester University",
  "Mandl School-The College of Allied Health",
  "Manhattan Area Technical College",
  "Manhattan Christian College",
  "Manhattan College",
  "Manhattan School of Music",
  "Manhattanville College",
  "Manor College",
  "Mansfield University of Pennsylvania",
  "Maple Springs Baptist Bible College and Seminary",
  "Maranatha Baptist University",
  "Maria College of Albany",
  "Marian University",
  "Marian University",
  "Marietta College",
  "Marion Military Institute",
  "Marion Technical College",
  "Marist College",
  "Marlboro College",
  "Marquette University",
  "Mars Hill University",
  "Marshall University",
  "Marshalltown Community College",
  "Martin Community College",
  "Martin Luther College",
  "Martin Methodist College",
  "Martin University",
  "Martinsburg College",
  "Mary Baldwin University",
  "Maryland Institute College of Art",
  "Marymount California University",
  "Marymount Manhattan College",
  "Marymount University",
  "Maryville College",
  "Maryville University of Saint Louis",
  "Marywood University",
  "Massachusetts Bay Community College",
  "Massachusetts College of Art and Design",
  "Massachusetts College of Liberal Arts",
  "Massachusetts Institute of Technology",
  "Massachusetts Maritime Academy",
  "Massasoit Community College",
  "Mayfield College",
  "Mayland Community College",
  "Maysville Community and Technical College",
  "Mayville State University",
  "McDaniel College",
  "McDowell Technical Community College",
  "McHenry County College",
  "McKendree University",
  "McLennan Community College",
  "McMurry University",
  "McNeese State University",
  "McPherson College",
  "Mechon L'hoyroa",
  "Med Academy",
  "Med-Life Institute-Lauderdale Lakes",
  "MedQuest College",
  "Medaille College",
  "MediaTech Institute-Dallas",
  "MediaTech Institute-Houston",
  "Medical Prep Institute of Tampa Bay",
  "Memorial College of Nursing",
  "Mendocino College",
  "Menlo College",
  "Merced College",
  "Mercer County Community College",
  "Mercer University",
  "Mercy College",
  "Mercy College of Health Sciences",
  "Mercy College of Ohio",
  "Mercyhurst University",
  "Mercyhurst University-North East Campus",
  "Meredith College",
  "Meridian College",
  "Meridian Community College",
  "Merrimack College",
  "Merritt College",
  "Mesa Community College",
  "Mesabi Range College",
  "Mesalands Community College",
  "Mesivta Torah Vodaath Rabbinical Seminary",
  "Mesivta of Eastern Parkway-Yeshiva Zichron Meilech",
  "Mesivtha Tifereth Jerusalem of America",
  "Messenger College",
  "Messiah College",
  "Methodist College",
  "Methodist University",
  "Metro Business College-Cape Girardeau",
  "Metro Business College-Jefferson City",
  "Metro Business College-Rolla",
  "Metropolitan College of New York",
  "Metropolitan Community College Area",
  "Metropolitan Community College-Kansas City",
  "Metropolitan State University",
  "Metropolitan State University of Denver",
  "Miami Dade College",
  "Miami International University of Art & Design-Art Institute Dallas",
  "Miami Media School",
  "Miami Regional College",
  "Miami University-Hamilton",
  "Miami University-Middletown",
  "Miami University-Oxford",
  "Michigan State University",
  "Michigan Technological University",
  "Mid Michigan College",
  "Mid Western University",
  "Mid-America Christian University",
  "Mid-America College of Funeral Service",
  "Mid-Atlantic Christian University",
  "Mid-Plains Community College",
  "Mid-South Christian College",
  "Mid-State Technical College",
  "MidAmerica Nazarene University",
  "Middle Georgia State University",
  "Middle Tennessee State University",
  "Middlebury College",
  "Middlesex Community College",
  "Middlesex Community College",
  "Middlesex County College",
  "Midland College",
  "Midland University",
  "Midlands Technical College",
  "Midstate College",
  "Midway University",
  "Midwest Institute",
  "Midwestern Baptist Theological Seminary",
  "Midwestern Career College",
  "Midwestern State University",
  "Midwives College of Utah",
  "Mildred Elley School-Albany Campus",
  "Mildred Elley-New York Campus",
  "Miles College",
  "Miles Community College",
  "Millennia Atlantic University",
  "Millersville University of Pennsylvania",
  "Milligan College",
  "Millikin University",
  "Mills College",
  "Millsaps College",
  "Milwaukee Area Technical College",
  "Milwaukee Career College",
  "Milwaukee Institute of Art & Design",
  "Milwaukee School of Engineering",
  "Mineral Area College",
  "Minerva Schools at Keck Graduate Institute",
  "Minneapolis Business College",
  "Minneapolis College of Art and Design",
  "Minneapolis Community and Technical College",
  "Minnesota State College Southeast",
  "Minnesota State Community and Technical College",
  "Minnesota State University Moorhead",
  "Minnesota State University-Mankato",
  "Minnesota West Community and Technical College",
  "Minot State University",
  "MiraCosta College",
  "Mirrer Yeshiva Cent Institute",
  "Misericordia University",
  "Mission College",
  "Mississippi College",
  "Mississippi Delta Community College",
  "Mississippi Gulf Coast Community College",
  "Mississippi State University",
  "Mississippi University for Women",
  "Mississippi Valley State University",
  "Missouri Baptist University",
  "Missouri Southern State University",
  "Missouri State University-Springfield",
  "Missouri State University-West Plains",
  "Missouri University of Science and Technology",
  "Missouri Valley College",
  "Missouri Western State University",
  "Mitchell College",
  "Mitchell Community College",
  "Mitchell Technical Institute",
  "Moberly Area Community College",
  "Modesto Junior College",
  "Mohave Community College",
  "Mohawk Valley Community College",
  "Molloy College",
  "Monmouth College",
  "Monmouth University",
  "Monroe College",
  "Monroe Community College",
  "Monroe County Community College",
  "Montana Bible College",
  "Montana State University",
  "Montana State University Billings",
  "Montana State University-Northern",
  "Montana Technological University",
  "Montcalm Community College",
  "Montclair State University",
  "Montefiore School of Nursing",
  "Monterey Peninsula College",
  "Montgomery College",
  "Montgomery Community College",
  "Montgomery County Community College",
  "Montreat College",
  "Montserrat College of Art",
  "Moody Bible Institute",
  "Moore College of Art and Design",
  "Moorpark College",
  "Moraine Park Technical College",
  "Moraine Valley Community College",
  "Moravian College",
  "Morehead State University",
  "Morehouse College",
  "Moreno Valley College",
  "Morgan Community College",
  "Morgan State University",
  "Morningside College",
  "Morris College",
  "Morrison Institute of Technology",
  "Morton College",
  "Motlow State Community College",
  "Mott Community College",
  "Mount Aloysius College",
  "Mount Angel Seminary",
  "Mount Carmel College of Nursing",
  "Mount Holyoke College",
  "Mount Marty College",
  "Mount Mary University",
  "Mount Mercy University",
  "Mount Saint Joseph University",
  "Mount Saint Mary College",
  "Mount Saint Mary's University",
  "Mount St. Mary's University",
  "Mount Vernon Nazarene University",
  "Mount Wachusett Community College",
  "Mountain Empire Community College",
  "Mountain State College",
  "Mountain View College",
  "Mountwest Community and Technical College",
  "Mt Hood Community College",
  "Mt San Antonio College",
  "Mt San Jacinto Community College District",
  "Mt Sierra College",
  "Muhlenberg College",
  "Multnomah University",
  "Murray State College",
  "Murray State University",
  "Musicians Institute",
  "Muskegon Community College",
  "Muskingum University",
  "MyComputerCareer.edu-Columbus",
  "Myotherapy Institute",
  "NHTI-Concord's Community College",
  "Napa Valley College",
  "Naropa University",
  "Nash Community College",
  "Nashua Community College",
  "Nashville State Community College",
  "Nassau Community College",
  "National American University-Albuquerque",
  "National American University-Albuquerque West",
  "National American University-Austin",
  "National American University-Bellevue",
  "National American University-Bloomington",
  "National American University-Brooklyn Center",
  "National American University-Burnsville",
  "National American University-Career Point College Teachout Site",
  "National American University-Centennial",
  "National American University-Colorado Springs",
  "National American University-Colorado Springs South",
  "National American University-Ellsworth AFB Extension",
  "National American University-Garden City",
  "National American University-Georgetown",
  "National American University-Houston",
  "National American University-Independence",
  "National American University-Indianapolis",
  "National American University-Killeen",
  "National American University-Lee's Summit",
  "National American University-Lewisville",
  "National American University-Mesquite",
  "National American University-Overland Park",
  "National American University-Rapid City",
  "National American University-Richardson",
  "National American University-Rochester",
  "National American University-Roseville",
  "National American University-Sioux Falls",
  "National American University-South Texas McAllen",
  "National American University-Tulsa",
  "National American University-Watertown",
  "National American University-Wichita",
  "National American University-Wichita West",
  "National American University-Zona Rosa",
  "National Career College",
  "National Career Education",
  "National College-Nashville",
  "National Louis University",
  "National Paralegal College",
  "National Park College",
  "National Polytechnic College",
  "National University",
  "Naugatuck Valley Community College",
  "Navajo Technical University",
  "Navarro College",
  "Nazarene Bible College",
  "Nazareth College",
  "Nebraska Christian College of Hope International University",
  "Nebraska College of Technical Agriculture",
  "Nebraska Indian Community College",
  "Nebraska Methodist College of Nursing & Allied Health",
  "Nebraska Wesleyan University",
  "Neosho County Community College",
  "Nepal Sanskrit University",
  "Ner Israel Rabbinical College",
  "Neumann University",
  "Neumont College of Computer Science",
  "Nevada Career Institute",
  "Nevada State College",
  "New Castle School of Trades",
  "New College of Florida",
  "New England College",
  "New England College of Business and Finance",
  "New England Culinary Institute",
  "New England Institute of Technology",
  "New Hampshire Institute of Art",
  "New Hope Christian College-Eugene",
  "New Jersey City University",
  "New Jersey Institute of Technology",
  "New Mexico Highlands University",
  "New Mexico Institute of Mining and Technology",
  "New Mexico Junior College",
  "New Mexico Military Institute",
  "New Mexico State University-Alamogordo",
  "New Mexico State University-Carlsbad",
  "New Mexico State University-Dona Ana",
  "New Mexico State University-Grants",
  "New Mexico State University-Main Campus",
  "New Orleans Baptist Theological Seminary",
  "New River Community College",
  "New River Community and Technical College",
  "New Saint Andrews College",
  "New York Automotive and Diesel Institute",
  "New York College of Health Professions",
  "New York Conservatory for Dramatic Arts",
  "New York Film Academy",
  "New York Institute of Technology",
  "New York School of Interior Design",
  "New York University",
  "Newberry College",
  "Newbury College",
  "Newman University",
  "Newschool of Architecture and Design",
  "Niagara County Community College",
  "Niagara University",
  "Nicholls State University",
  "Nichols College",
  "Nicolet Area Technical College",
  "Nightingale College",
  "Norco College",
  "Norfolk State University",
  "Normandale Community College",
  "North American University",
  "North Arkansas College",
  "North Carolina A & T State University",
  "North Carolina Central University",
  "North Carolina State University at Raleigh",
  "North Carolina Wesleyan College",
  "North Central College",
  "North Central Institute",
  "North Central Kansas Technical College",
  "North Central Michigan College",
  "North Central Missouri College",
  "North Central State College",
  "North Central Texas College",
  "North Central University",
  "North Country Community College",
  "North Dakota State College of Science",
  "North Dakota State University-Main Campus",
  "North Florida Community College",
  "North Georgia Technical College",
  "North Greenville University",
  "North Hennepin Community College",
  "North Idaho College",
  "North Iowa Area Community College",
  "North Lake College",
  "North Park University",
  "North Seattle College",
  "North Shore Community College",
  "North-West College-West Covina",
  "NorthWest Arkansas Community College",
  "Northampton County Area Community College",
  "Northcentral Technical College",
  "Northeast Alabama Community College",
  "Northeast Catholic College",
  "Northeast Community College",
  "Northeast Iowa Community College",
  "Northeast Lakeview College",
  "Northeast Mississippi Community College",
  "Northeast State Community College",
  "Northeast Texas Community College",
  "Northeast Wisconsin Technical College",
  "Northeastern Illinois University",
  "Northeastern Junior College",
  "Northeastern Oklahoma A&M College",
  "Northeastern State University",
  "Northeastern Technical College",
  "Northeastern University",
  "Northern Arizona University",
  "Northern Essex Community College",
  "Northern Illinois University",
  "Northern Kentucky University",
  "Northern Maine Community College",
  "Northern Michigan University",
  "Northern New Mexico College",
  "Northern Oklahoma College",
  "Northern State University",
  "Northern Vermont University",
  "Northern Virginia Community College",
  "Northern Wyoming Community College District",
  "Northland College",
  "Northland Community and Technical College",
  "Northland Pioneer College",
  "Northpoint Bible College",
  "Northshore Technical Community College",
  "Northwest Career College",
  "Northwest Christian University",
  "Northwest College",
  "Northwest College of Art & Design",
  "Northwest Florida State College",
  "Northwest Indian College",
  "Northwest Iowa Community College",
  "Northwest Kansas Technical College",
  "Northwest Louisiana Technical College",
  "Northwest Mississippi Community College",
  "Northwest Missouri State University",
  "Northwest Nazarene University",
  "Northwest School of Wooden Boat Building",
  "Northwest State Community College",
  "Northwest Technical College",
  "Northwest University",
  "Northwest University-College of Adult and Professional Studies",
  "Northwest Vista College",
  "Northwest-Shoals Community College",
  "Northwestern College",
  "Northwestern College-Southwestern Campus",
  "Northwestern Connecticut Community College",
  "Northwestern Health Sciences University",
  "Northwestern Michigan College",
  "Northwestern Oklahoma State University",
  "Northwestern State University of Louisiana",
  "Northwestern University",
  "Northwood University",
  "Norwalk Community College",
  "Norwich University",
  "Nossi College of Art",
  "Notre Dame College",
  "Notre Dame de Namur University",
  "Notre Dame of Maryland University",
  "Nova Southeastern University",
  "Nueta Hidatsa Sahnish College",
  "Nunez Community College",
  "Nyack College",
  "Oak Hills Christian College",
  "Oakland City University",
  "Oakland Community College",
  "Oakland University",
  "Oakton Community College",
  "Oakwood University",
  "Oberlin College",
  "Occidental College",
  "Ocean County College",
  "Oconee Fall Line Technical College",
  "Odessa College",
  "Ogeechee Technical College",
  "Oglala Lakota College",
  "Oglethorpe University",
  "Ohio Business College-Sandusky",
  "Ohio Business College-Sheffield",
  "Ohio Christian University",
  "Ohio Dominican University",
  "Ohio Northern University",
  "Ohio State University Agricultural Technical Institute",
  "Ohio State University-Lima Campus",
  "Ohio State University-Main Campus",
  "Ohio State University-Mansfield Campus",
  "Ohio State University-Marion Campus",
  "Ohio State University-Newark Campus",
  "Ohio Technical College",
  "Ohio University-Chillicothe Campus",
  "Ohio University-Eastern Campus",
  "Ohio University-Lancaster Campus",
  "Ohio University-Main Campus",
  "Ohio University-Southern Campus",
  "Ohio University-Zanesville Campus",
  "Ohio Valley College of Technology",
  "Ohio Valley University",
  "Ohio Wesleyan University",
  "Ohlone College",
  "Ohr Hameir Theological Seminary",
  "Oikos University",
  "Oklahoma Baptist University",
  "Oklahoma Christian University",
  "Oklahoma City Community College",
  "Oklahoma City University",
  "Oklahoma Panhandle State University",
  "Oklahoma State University Institute of Technology",
  "Oklahoma State University-Main Campus",
  "Oklahoma State University-Oklahoma City",
  "Oklahoma Wesleyan University",
  "Old Dominion University",
  "Olivet College",
  "Olivet Nazarene University",
  "Olney Central College",
  "Olympic College",
  "Onondaga Community College",
  "Oral Roberts University",
  "Orange Coast College",
  "Orange County Community College",
  "Orangeburg Calhoun Technical College",
  "Oregon Coast Community College",
  "Oregon College of Art and Craft",
  "Oregon Institute of Technology",
  "Oregon State University",
  "Oregon State University-Cascades Campus",
  "Orleans Technical College",
  "Otero Junior College",
  "Otis College of Art and Design",
  "Ottawa University-Ottawa",
  "Ottawa University-Surprise",
  "Otterbein University",
  "Ouachita Baptist University",
  "Our Lady of the Lake University",
  "Owens Community College",
  "Owensboro Community and Technical College",
  "Oxnard College",
  "Ozark Christian College",
  "Ozarka College",
  "Ozarks Technical Community College",
  "PCI College",
  "Pace University",
  "Pacific Bible College",
  "Pacific College",
  "Pacific College of Oriental Medicine-Chicago",
  "Pacific College of Oriental Medicine-New York",
  "Pacific College of Oriental Medicine-San Diego",
  "Pacific Lutheran University",
  "Pacific Northwest College of Art",
  "Pacific Oaks College",
  "Pacific Rim Christian University",
  "Pacific States University",
  "Pacific Union College",
  "Pacific University",
  "Paier College of Art Inc",
  "Paine College",
  "Palm Beach Atlantic University",
  "Palm Beach State College",
  "Palmer College of Chiropractic",
  "Palo Alto College",
  "Palo Verde College",
  "Palomar College",
  "Pamlico Community College",
  "Panola College",
  "Paradise Valley Community College",
  "Paris Junior College",
  "Park University",
  "Parker University",
  "Parkland College",
  "Pasadena City College",
  "Pasco-Hernando State College",
  "Passaic County Community College",
  "Patrick Henry Community College",
  "Paul D Camp Community College",
  "Paul Quinn College",
  "Paul Smiths College of Arts and Science",
  "Pearl River Community College",
  "Peirce College",
  "Pellissippi State Community College",
  "Peloton College",
  "Peninsula College",
  "Penn Commercial Business/Technical School",
  "Pennco Tech-Bristol",
  "Pennsylvania Academy of the Fine Arts",
  "Pennsylvania College of Art and Design",
  "Pennsylvania College of Health Sciences",
  "Pennsylvania College of Technology",
  "Pennsylvania Highlands Community College",
  "Pennsylvania Institute of Health and Technology",
  "Pennsylvania Institute of Technology",
  "Pennsylvania State University-Main Campus",
  "Pennsylvania State University-Penn State Abington",
  "Pennsylvania State University-Penn State Altoona",
  "Pennsylvania State University-Penn State Beaver",
  "Pennsylvania State University-Penn State Berks",
  "Pennsylvania State University-Penn State Brandywine",
  "Pennsylvania State University-Penn State DuBois",
  "Pennsylvania State University-Penn State Erie-Behrend College",
  "Pennsylvania State University-Penn State Fayette- Eberly",
  "Pennsylvania State University-Penn State Greater Allegheny",
  "Pennsylvania State University-Penn State Harrisburg",
  "Pennsylvania State University-Penn State Hazleton",
  "Pennsylvania State University-Penn State Lehigh Valley",
  "Pennsylvania State University-Penn State Mont Alto",
  "Pennsylvania State University-Penn State New Kensington",
  "Pennsylvania State University-Penn State Schuylkill",
  "Pennsylvania State University-Penn State Scranton",
  "Pennsylvania State University-Penn State Shenango",
  "Pennsylvania State University-Penn State Wilkes-Barre",
  "Pennsylvania State University-Penn State York",
  "Pennsylvania State University-World Campus",
  "Pensacola State College",
  "Pepperdine University",
  "Perry Technical Institute",
  "Peru State College",
  "Pfeiffer University",
  "Philander Smith College",
  "Phillips Community College of the University of Arkansas",
  "Phillips School of Nursing at Mount Sinai Beth Israel",
  "Phoenix College",
  "Piedmont College",
  "Piedmont Community College",
  "Piedmont International University",
  "Piedmont Technical College",
  "Piedmont Virginia Community College",
  "Pierce College-Fort Steilacoom",
  "Pierce College-Puyallup",
  "Pierpont Community and Technical College",
  "Pikes Peak Community College",
  "Pillar College",
  "Pima Community College",
  "Pima Medical Institute-Albuquerque",
  "Pima Medical Institute-Albuquerque West",
  "Pima Medical Institute-Aurora",
  "Pima Medical Institute-Chula Vista",
  "Pima Medical Institute-Colorado Springs",
  "Pima Medical Institute-Denver",
  "Pima Medical Institute-Dillon",
  "Pima Medical Institute-East Valley",
  "Pima Medical Institute-El Paso",
  "Pima Medical Institute-Houston",
  "Pima Medical Institute-Las Vegas",
  "Pima Medical Institute-Mesa",
  "Pima Medical Institute-Phoenix",
  "Pima Medical Institute-Renton",
  "Pima Medical Institute-San Marcos",
  "Pima Medical Institute-Seattle",
  "Pima Medical Institute-Tucson",
  "Pine Manor College",
  "Pine Technical & Community College",
  "Pinnacle Career Institute-North Kansas City",
  "Pinnacle Career Institute-South Kansas City",
  "Pioneer Pacific College",
  "Pitt Community College",
  "Pittsburg State University",
  "Pittsburgh Career Institute",
  "Pittsburgh Institute of Aeronautics",
  "Pittsburgh Institute of Mortuary Science Inc",
  "Pittsburgh Technical College",
  "Pitzer College",
  "Platt College",
  "Platt College-Anaheim",
  "Platt College-Aurora",
  "Platt College-Berks Technical Institute",
  "Platt College-Los Angeles",
  "Platt College-McCann-Allentown",
  "Platt College-McCann-Lewisburg",
  "Platt College-McCann-Monroe",
  "Platt College-Miller-Motte Technical-Augusta",
  "Platt College-Miller-Motte Technical-Charleston",
  "Platt College-Miller-Motte Technical-Chattanooga",
  "Platt College-Miller-Motte Technical-Columbus",
  "Platt College-Miller-Motte Technical-Conway",
  "Platt College-Miller-Motte Technical-Macon",
  "Platt College-Miller-Motte-Cary",
  "Platt College-Miller-Motte-Fayetteville",
  "Platt College-Miller-Motte-Jacksonville",
  "Platt College-Miller-Motte-Raleigh",
  "Platt College-Miller-Motte-Wilmington",
  "Platt College-Moore",
  "Platt College-Ontario",
  "Platt College-Riverside",
  "Platt College-San Diego",
  "Plaza College",
  "Plymouth State University",
  "Point Loma Nazarene University",
  "Point Park University",
  "Point University",
  "Pokhara University",
  "Polk State College",
  "Polytechnic University of Puerto Rico-Miami",
  "Polytechnic University of Puerto Rico-Orlando",
  "Pomeroy College of Nursing at Crouse Hospital",
  "Pomona College",
  "Pontifical College Josephinum",
  "Porterville College",
  "Portland Community College",
  "Portland State University",
  "Post University",
  "Potomac State College of West Virginia University",
  "Prairie State College",
  "Prairie View A & M University",
  "Pratt Community College",
  "Pratt Institute-Main",
  "Presbyterian College",
  "Presbyterian Theological Seminary in America",
  "Prescott College",
  "Presentation College",
  "Prince George's Community College",
  "Princeton University",
  "Principia College",
  "Professional Golfers Career College",
  "Professional Hands Institute",
  "Professional Skills Institute",
  "Providence Christian College",
  "Providence College",
  "Provo College",
  "Pueblo Community College",
  "Purbanchal University",
  "Purdue University Fort Wayne",
  "Purdue University Global-Augusta",
  "Purdue University Global-Cedar Falls",
  "Purdue University Global-Cedar Rapids",
  "Purdue University Global-Davenport",
  "Purdue University Global-Des Moines",
  "Purdue University Global-Hagerstown",
  "Purdue University Global-Indianapolis",
  "Purdue University Global-Lewiston",
  "Purdue University Global-Lincoln",
  "Purdue University Global-Mason City",
  "Purdue University Global-Milwaukee",
  "Purdue University Global-Omaha",
  "Purdue University Global-St. Louis",
  "Purdue University Northwest",
  "Purdue University-Main Campus",
  "Queens University of Charlotte",
  "Quincy College",
  "Quincy University",
  "Quinebaug Valley Community College",
  "Quinnipiac University",
  "Quinsigamond Community College",
  "Rabbi Jacob Joseph School",
  "Rabbinical Academy Mesivta Rabbi Chaim Berlin",
  "Rabbinical College Beth Shraga",
  "Rabbinical College Bobover Yeshiva Bnei Zion",
  "Rabbinical College Ohr Yisroel",
  "Rabbinical College Telshe",
  "Rabbinical College of America",
  "Rabbinical College of Long Island",
  "Rabbinical College of Ohr Shimon Yisroel",
  "Rabbinical Seminary of America",
  "Radford University",
  "Rainy River Community College",
  "Ramapo College of New Jersey",
  "Randall University",
  "Randolph College",
  "Randolph Community College",
  "Randolph-Macon College",
  "Ranger College",
  "Ranken Technical College",
  "Rappahannock Community College",
  "Raritan Valley Community College",
  "Rasmussen College-Florida",
  "Rasmussen College-Illinois",
  "Rasmussen College-Kansas",
  "Rasmussen College-Minnesota",
  "Rasmussen College-North Dakota",
  "Rasmussen College-Wisconsin",
  "Reading Area Community College",
  "Red Lake Nation College",
  "Red Rocks Community College",
  "Redlands Community College",
  "Reed College",
  "Reedley College",
  "Reformed University",
  "Refrigeration School Inc",
  "Regent University",
  "Regis College",
  "Regis University",
  "Reid State Technical College",
  "Reinhardt University",
  "Remington College-Baton Rouge Campus",
  "Remington College-Cleveland Campus",
  "Remington College-Dallas Campus",
  "Remington College-Fort Worth Campus",
  "Remington College-Heathrow Campus",
  "Remington College-Honolulu Campus",
  "Remington College-Houston Southeast Campus",
  "Remington College-Lafayette Campus",
  "Remington College-Little Rock Campus",
  "Remington College-Memphis Campus",
  "Remington College-Mobile Campus",
  "Remington College-Nashville Campus",
  "Remington College-North Houston Campus",
  "Remington College-Shreveport Campus",
  "Rend Lake College",
  "Rensselaer Polytechnic Institute",
  "Renton Technical College",
  "Rhode Island College",
  "Rhode Island School of Design",
  "Rhodes College",
  "Rice University",
  "Richard Bland College",
  "Richland College",
  "Richland Community College",
  "Richmond Community College",
  "Rider University",
  "Ridgewater College",
  "Ringling College of Art and Design",
  "Rio Hondo College",
  "Rio Salado College",
  "Ripon College",
  "River Parishes Community College",
  "River Valley Community College",
  "Riverland Community College",
  "Riverside City College",
  "Riverside College of Health Careers",
  "Rivier University",
  "Roane State Community College",
  "Roanoke College",
  "Roanoke-Chowan Community College",
  "Robert Morris University",
  "Robert Morris University Illinois",
  "Roberts Wesleyan College",
  "Robeson Community College",
  "Rochester College",
  "Rochester Community and Technical College",
  "Rochester Institute of Technology",
  "Rock Valley College",
  "Rockford University",
  "Rockhurst University",
  "Rockingham Community College",
  "Rockland Community College",
  "Rocky Mountain College",
  "Rocky Mountain College of Art and Design",
  "Roger Williams University",
  "Rogers State University",
  "Rogue Community College",
  "Rollins College",
  "Roosevelt University",
  "Rose State College",
  "Rose-Hulman Institute of Technology",
  "Rosedale Bible College",
  "Rosedale Technical College",
  "Rosemont College",
  "Ross College-Canton",
  "Ross College-Hopkinsville",
  "Ross College-Quad Cities",
  "Ross College-Sylvania",
  "Ross Medical Education Center-Evansville",
  "Ross Medical Education Center-Fort Wayne",
  "Ross Medical Education Center-Granger",
  "Ross Medical Education Center-Kokomo",
  "Ross Medical Education Center-Lafayette",
  "Ross Medical Education Center-Muncie",
  "Rowan College at Burlington County",
  "Rowan College at Gloucester County",
  "Rowan University",
  "Rowan-Cabarrus Community College",
  "Roxbury Community College",
  "Rust College",
  "Rutgers University-Camden",
  "Rutgers University-New Brunswick",
  "Rutgers University-Newark",
  "SABER College",
  "SAE Expression College",
  "SAE Institute of Technology-Atlanta",
  "SAE Institute of Technology-Chicago",
  "SAE Institute of Technology-Nashville",
  "SOWELA Technical Community College",
  "SUM Bible College and Theological Seminary",
  "SUNY Broome Community College",
  "SUNY Buffalo State",
  "SUNY College at Brockport",
  "SUNY College at Geneseo",
  "SUNY College at Old Westbury",
  "SUNY College at Oswego",
  "SUNY College at Plattsburgh",
  "SUNY College at Potsdam",
  "SUNY College of Agriculture and Technology at Cobleskill",
  "SUNY College of Environmental Science and Forestry",
  "SUNY College of Technology at Alfred",
  "SUNY College of Technology at Canton",
  "SUNY College of Technology at Delhi",
  "SUNY Cortland",
  "SUNY Empire State College",
  "SUNY Maritime College",
  "SUNY Morrisville",
  "SUNY Oneonta",
  "SUNY Polytechnic Institute",
  "SUNY Westchester Community College",
  "SUNY at Albany",
  "SUNY at Fredonia",
  "SUNY at Purchase College",
  "Sacramento City College",
  "Sacramento Ultrasound Institute",
  "Sacred Heart Major Seminary",
  "Sacred Heart University",
  "Saddleback College",
  "Saginaw Chippewa Tribal College",
  "Saginaw Valley State University",
  "Saint Ambrose University",
  "Saint Anselm College",
  "Saint Anthony College of Nursing",
  "Saint Augustine College",
  "Saint Augustine's University",
  "Saint Charles Borromeo Seminary-Overbrook",
  "Saint Cloud State University",
  "Saint Edward's University",
  "Saint Elizabeth College of Nursing",
  "Saint Francis University",
  "Saint John Fisher College",
  "Saint John Vianney College Seminary",
  "Saint John's Seminary",
  "Saint Johns River State College",
  "Saint Johns University",
  "Saint Joseph Seminary College",
  "Saint Joseph's College of Maine",
  "Saint Joseph's University",
  "Saint Leo University",
  "Saint Louis Christian College",
  "Saint Louis Community College",
  "Saint Louis University",
  "Saint Martin's University",
  "Saint Mary's College",
  "Saint Mary's College of California",
  "Saint Mary's University of Minnesota",
  "Saint Mary-of-the-Woods College",
  "Saint Michael College of Allied Health",
  "Saint Michael's College",
  "Saint Norbert College",
  "Saint Paul College",
  "Saint Peter's University",
  "Saint Vincent College",
  "Saint Xavier University",
  "Salem College",
  "Salem Community College",
  "Salem State University",
  "Salem University",
  "Salina Area Technical College",
  "Salisbury University",
  "Salish Kootenai College",
  "Salt Lake Community College",
  "Salter College-Chicopee",
  "Salter College-West Boylston",
  "Salve Regina University",
  "Sam Houston State University",
  "Samaritan Hospital School of Nursing",
  "Samford University",
  "Sampson Community College",
  "San Antonio College",
  "San Bernardino Valley College",
  "San Diego Christian College",
  "San Diego City College",
  "San Diego Mesa College",
  "San Diego Miramar College",
  "San Diego State University",
  "San Francisco Art Institute",
  "San Francisco Conservatory of Music",
  "San Francisco Film School",
  "San Francisco State University",
  "San Ignacio University",
  "San Jacinto Community College",
  "San Joaquin Delta College",
  "San Joaquin Valley College-Visalia",
  "San Jose City College",
  "San Jose State University",
  "San Juan College",
  "Sandhills Community College",
  "Santa Ana College",
  "Santa Barbara Business College-Bakersfield",
  "Santa Barbara Business College-Santa Maria",
  "Santa Barbara City College",
  "Santa Clara University",
  "Santa Fe College",
  "Santa Fe Community College",
  "Santa Monica College",
  "Santa Rosa Junior College",
  "Santiago Canyon College",
  "Sarah Lawrence College",
  "Sauk Valley Community College",
  "Savannah College of Art and Design",
  "Savannah State University",
  "Savannah Technical College",
  "Schenectady County Community College",
  "Schiller International University",
  "School of Automotive Machinists & Technology",
  "School of Visual Arts",
  "School of the Art Institute of Chicago",
  "Schoolcraft College",
  "Schreiner University",
  "Scottsdale Community College",
  "Scripps College",
  "Seattle Central College",
  "Seattle Film Institute",
  "Seattle Pacific University",
  "Seattle University",
  "Selma University",
  "Seminar L'moros Bais Yaakov",
  "Seminary Bnos Chaim",
  "Seminole State College",
  "Seminole State College of Florida",
  "Sessions College for Professional Design",
  "Seton Hall University",
  "Seton Hill University",
  "Seward County Community College",
  "Sh'or Yoshuv Rabbinical College",
  "Shasta Bible College and Graduate School",
  "Shasta College",
  "Shaw University",
  "Shawnee Community College",
  "Shawnee State University",
  "Shelton State Community College",
  "Shenandoah University",
  "Shepherd University",
  "Shiloh University",
  "Shippensburg University of Pennsylvania",
  "Shoreline Community College",
  "Shorter College",
  "Shorter University",
  "Shorter University-College of Adult & Professional Programs",
  "Siena College",
  "Siena Heights University",
  "Sierra College",
  "Sierra Nevada College",
  "Silver Lake College of the Holy Family",
  "Simmons College of Kentucky",
  "Simmons University",
  "Simpson College",
  "Simpson University",
  "Sinclair Community College",
  "Sinte Gleska University",
  "Sisseton Wahpeton College",
  "Sitting Bull College",
  "Skagit Valley College",
  "Skidmore College",
  "Skyline College",
  "Slippery Rock University of Pennsylvania",
  "Smith College",
  "Snead State Community College",
  "Snow College",
  "Soka University of America",
  "Solano Community College",
  "Somerset Community College",
  "Sonoma State University",
  "Sonoran Desert Institute",
  "South Arkansas Community College",
  "South Carolina State University",
  "South Central College",
  "South Central Louisiana Technical College",
  "South Coast College",
  "South College",
  "South Dakota School of Mines and Technology",
  "South Dakota State University",
  "South Florida Bible College and Theological Seminary",
  "South Florida State College",
  "South Georgia State College",
  "South Georgia Technical College",
  "South Hills School of Business & Technology",
  "South Louisiana Community College",
  "South Mountain Community College",
  "South Piedmont Community College",
  "South Plains College",
  "South Puget Sound Community College",
  "South Seattle College",
  "South Suburban College",
  "South Texas College",
  "South University-Austin",
  "South University-Columbia",
  "South University-Montgomery",
  "South University-Savannah",
  "South University-Tampa",
  "South University-West Palm Beach",
  "South University–Richmond",
  "South University–Savannah Online",
  "South University–Virginia Beach",
  "Southcentral Kentucky Community and Technical College",
  "Southeast Arkansas College",
  "Southeast Community College Area",
  "Southeast Kentucky Community and Technical College",
  "Southeast Missouri Hospital College of Nursing and Health Sciences",
  "Southeast Missouri State University",
  "Southeast Technical Institute",
  "Southeastern Baptist College",
  "Southeastern Baptist Theological Seminary",
  "Southeastern College-West Palm Beach",
  "Southeastern Community College",
  "Southeastern Community College",
  "Southeastern Illinois College",
  "Southeastern Institute-Charleston",
  "Southeastern Institute-Columbia",
  "Southeastern Louisiana University",
  "Southeastern Oklahoma State University",
  "Southeastern Technical College",
  "Southeastern University",
  "Southern Adventist University",
  "Southern Arkansas University Main Campus",
  "Southern Arkansas University Tech",
  "Southern California Institute of Architecture",
  "Southern California Institute of Technology",
  "Southern California Seminary",
  "Southern California University of Health Sciences",
  "Southern Careers Institute-Austin",
  "Southern Connecticut State University",
  "Southern Crescent Technical College",
  "Southern Illinois University-Carbondale",
  "Southern Illinois University-Edwardsville",
  "Southern Maine Community College",
  "Southern Methodist University",
  "Southern Nazarene University",
  "Southern New Hampshire University",
  "Southern Oregon University",
  "Southern Regional Technical College",
  "Southern State Community College",
  "Southern States University",
  "Southern Technical College",
  "Southern Technical College",
  "Southern Union State Community College",
  "Southern University and A & M College",
  "Southern University at New Orleans",
  "Southern University at Shreveport",
  "Southern Utah University",
  "Southern Vermont College",
  "Southern Virginia University",
  "Southern Wesleyan University",
  "Southern West Virginia Community and Technical College",
  "Southside Regional Medical Center Professional Schools",
  "Southside Virginia Community College",
  "Southwest Baptist University",
  "Southwest Collegiate Institute for the Deaf",
  "Southwest Institute of Healing Arts",
  "Southwest Minnesota State University",
  "Southwest Mississippi Community College",
  "Southwest Tennessee Community College",
  "Southwest Texas Junior College",
  "Southwest University at El Paso",
  "Southwest University of Visual Arts-Albuquerque",
  "Southwest University of Visual Arts-Tucson",
  "Southwest Virginia Community College",
  "Southwest Wisconsin Technical College",
  "Southwestern Adventist University",
  "Southwestern Assemblies of God University",
  "Southwestern Christian College",
  "Southwestern Christian University",
  "Southwestern College",
  "Southwestern College",
  "Southwestern Community College",
  "Southwestern Community College",
  "Southwestern Illinois College",
  "Southwestern Indian Polytechnic Institute",
  "Southwestern Michigan College",
  "Southwestern Oklahoma State University",
  "Southwestern Oregon Community College",
  "Southwestern University",
  "Spalding University",
  "Spartan College of Aeronautics & Technology",
  "Spartan College of Aeronautics and Technology",
  "Spartan College of Aeronautics and Technology",
  "Spartanburg Community College",
  "Spartanburg Methodist College",
  "Spelman College",
  "Spokane Community College",
  "Spokane Falls Community College",
  "Spoon River College",
  "Spring Arbor University",
  "Spring Hill College",
  "Springfield College",
  "Springfield Technical Community College",
  "St Bonaventure University",
  "St Catherine University",
  "St Charles Community College",
  "St Clair County Community College",
  "St Cloud Technical and Community College",
  "St Francis College",
  "St John's University-New York",
  "St Joseph School of Nursing",
  "St Lawrence University",
  "St Louis College of Health Careers-Fenton",
  "St Louis College of Health Careers-St Louis",
  "St Luke's College",
  "St Olaf College",
  "St Paul's School of Nursing-Queens",
  "St Paul's School of Nursing-Staten Island",
  "St Petersburg College",
  "St Philip's College",
  "St. Andrews University",
  "St. John's College",
  "St. John's College",
  "St. Joseph's College of Nursing",
  "St. Joseph's College-Long Island",
  "St. Joseph's College-New York",
  "St. Louis College of Pharmacy",
  "St. Mary's College of Maryland",
  "St. Mary's University",
  "St. Thomas Aquinas College",
  "St. Thomas University",
  "Stanbridge University",
  "Stanbridge University-Los Angeles",
  "Standard Healthcare Services-College of Nursing",
  "Stanford University",
  "Stanly Community College",
  "Stark State College",
  "State College of Florida-Manatee-Sarasota",
  "State Fair Community College",
  "State Technical College of Missouri",
  "State University of New York at New Paltz",
  "Stautzenberger College-Brecksville",
  "Stautzenberger College-Maumee",
  "Stella and Charles Guttman Community College",
  "Stephen F Austin State University",
  "Stephens College",
  "Sterling College",
  "Sterling College",
  "Stetson University",
  "Stevens Institute of Technology",
  "Stevens-Henager College",
  "Stevens-Henager College",
  "Stevens-Henager College",
  "Stevens-Henager College",
  "Stevens-Henager College",
  "Stevens-Henager College",
  "Stevens-Henager College",
  "Stevens-The Institute of Business & Arts",
  "Stevenson University",
  "Stillman College",
  "Stockton University",
  "Stone Child College",
  "Stonehill College",
  "Stony Brook University",
  "Stratford University",
  "Strayer University-Alabama",
  "Strayer University-Arkansas",
  "Strayer University-Delaware",
  "Strayer University-District of Columbia",
  "Strayer University-Florida",
  "Strayer University-Georgia",
  "Strayer University-Global Region",
  "Strayer University-Maryland",
  "Strayer University-Mississippi",
  "Strayer University-New Jersey",
  "Strayer University-North Carolina",
  "Strayer University-Pennsylvania",
  "Strayer University-South Carolina",
  "Strayer University-Tennessee",
  "Strayer University-Texas",
  "Strayer University-Virginia",
  "Strayer University-West Virginia",
  "Suffolk County Community College",
  "Suffolk University",
  "Sul Ross State University",
  "Sullivan County Community College",
  "Sullivan University",
  "Summit Christian College",
  "Sumner College",
  "Sunstate Academy-Jones Technical Institute",
  "Surry Community College",
  "Susquehanna University",
  "Sussex County Community College",
  "Swarthmore College",
  "Swedish Institute a College of Health Sciences",
  "Sweet Briar College",
  "Syracuse University",
  "Tabor College",
  "Tacoma Community College",
  "Taft College",
  "Talladega College",
  "Tallahassee Community College",
  "Talmudic College of Florida",
  "Talmudical Academy-New Jersey",
  "Talmudical Institute of Upstate New York",
  "Talmudical Seminary Oholei Torah",
  "Talmudical Seminary of Bobov",
  "Talmudical Yeshiva of Philadelphia",
  "Tarleton State University",
  "Tarrant County College District",
  "Taylor Business Institute",
  "Taylor College",
  "Taylor University",
  "Technical College of the Lowcountry",
  "Telshe Yeshiva-Chicago",
  "Temple College",
  "Temple University",
  "Tennessee State University",
  "Tennessee Technological University",
  "Tennessee Wesleyan University",
  "Terra State Community College",
  "Texarkana College",
  "Texas A & M International University",
  "Texas A & M University-College Station",
  "Texas A & M University-Commerce",
  "Texas A & M University-Corpus Christi",
  "Texas A & M University-Kingsville",
  "Texas A&M University-San Antonio",
  "Texas A&M University-Texarkana",
  "Texas Christian University",
  "Texas College",
  "Texas County Technical College",
  "Texas Lutheran University",
  "Texas Southern University",
  "Texas Southmost College",
  "Texas State Technical College",
  "Texas State University",
  "Texas Tech University",
  "Texas Wesleyan University",
  "Texas Woman's University",
  "Thaddeus Stevens College of Technology",
  "The Art Institute of Atlanta",
  "The Art Institute of Austin",
  "The Art Institute of Cincinnati-AIC College of Design",
  "The Art Institute of Houston",
  "The Art Institute of Las Vegas",
  "The Art Institute of Pittsburgh",
  "The Art Institute of Pittsburgh-Online Division",
  "The Art Institute of San Antonio",
  "The Art Institute of Seattle",
  "The Art Institute of Virginia Beach",
  "The Baptist College of Florida",
  "The Chicago School of Professional Psychology at Los Angeles",
  "The Christ College of Nursing and Health Sciences",
  "The Colburn Conservatory of Music",
  "The College of Health Care Professions-Austin",
  "The College of Health Care Professions-Fort Worth",
  "The College of Health Care Professions-McAllen Campus",
  "The College of Health Care Professions-Northwest",
  "The College of Health Care Professions-San Antonio",
  "The College of Health Care Professions-South San Antonio",
  "The College of Health Care Professions-Southwest Houston",
  "The College of Idaho",
  "The College of New Jersey",
  "The College of New Rochelle",
  "The College of Saint Rose",
  "The College of Saint Scholastica",
  "The College of Westchester",
  "The College of Wooster",
  "The Creative Center",
  "The Evergreen State College",
  "The Juilliard School",
  "The King's College",
  "The King's University",
  "The Landing School",
  "The Master's University and Seminary",
  "The New England Conservatory of Music",
  "The New School",
  "The North Coast College",
  "The Restaurant School at Walnut Hill College",
  "The Sage Colleges",
  "The Southern Baptist Theological Seminary",
  "The University of Alabama",
  "The University of America",
  "The University of Findlay",
  "The University of Montana",
  "The University of Montana-Western",
  "The University of Tampa",
  "The University of Tennessee Institute for Public Service",
  "The University of Tennessee-Chattanooga",
  "The University of Tennessee-Knoxville",
  "The University of Tennessee-Martin",
  "The University of Texas Rio Grande Valley",
  "The University of Texas at Arlington",
  "The University of Texas at Austin",
  "The University of Texas at Dallas",
  "The University of Texas at El Paso",
  "The University of Texas at San Antonio",
  "The University of Texas at Tyler",
  "The University of Texas of the Permian Basin",
  "The University of Virginia's College at Wise",
  "The University of West Florida",
  "The University of the Arts",
  "The University of the South",
  "The Workforce Institute's City College",
  "Theatre of Arts",
  "Thiel College",
  "Thomas Aquinas College",
  "Thomas College",
  "Thomas Edison State University",
  "Thomas Jefferson University",
  "Thomas More College",
  "Thomas More College of Liberal Arts",
  "Thomas Nelson Community College",
  "Thomas University",
  "Three Rivers College",
  "Three Rivers Community College",
  "Tidewater Community College",
  "Tiffin University",
  "Tillamook Bay Community College",
  "Toccoa Falls College",
  "Tohono O'Odham Community College",
  "Tompkins Cortland Community College",
  "Torah Temimah Talmudical Seminary",
  "Tougaloo College",
  "Touro College",
  "Touro University Worldwide",
  "Towson University",
  "Transylvania University",
  "Treasure Valley Community College",
  "Trevecca Nazarene University",
  "Tri-County Community College",
  "Tri-County Technical College",
  "Tri-State Bible College",
  "Triangle Tech Inc-Bethlehem",
  "Triangle Tech Inc-Dubois",
  "Triangle Tech Inc-Erie",
  "Triangle Tech Inc-Greensburg",
  "Triangle Tech Inc-Pittsburgh",
  "Triangle Tech Inc-Sunbury",
  "Tribhuvan University",
  "Trident Technical College",
  "Trident University International",
  "Trine University",
  "Trine University-Regional/Non-Traditional Campuses",
  "Trinidad State Junior College",
  "Trinity Baptist College",
  "Trinity Bible College and Graduate School",
  "Trinity Christian College",
  "Trinity College",
  "Trinity College of Florida",
  "Trinity College of Nursing & Health Sciences",
  "Trinity International University-Florida",
  "Trinity International University-Illinois",
  "Trinity School of Health and Allied Sciences",
  "Trinity University",
  "Trinity Valley Community College",
  "Trinity Washington University",
  "Triton College",
  "Trocaire College",
  "Troy University",
  "Truckee Meadows Community College",
  "Truett McConnell University",
  "Truman State University",
  "Tufts University",
  "Tulane University of Louisiana",
  "Tulsa Community College",
  "Tulsa Welding School-Tulsa",
  "Tunxis Community College",
  "Turtle Mountain Community College",
  "Tusculum University",
  "Tuskegee University",
  "Tyler Junior College",
  "Ulster County Community College",
  "Ultimate Medical Academy-Clearwater",
  "Ultrasound Medical Institute",
  "Umpqua Community College",
  "Unilatina International College",
  "Union Bible College",
  "Union College",
  "Union College",
  "Union College",
  "Union County College",
  "Union Institute & University",
  "Union University",
  "United States Air Force Academy",
  "United States Coast Guard Academy",
  "United States Merchant Marine Academy",
  "United States Military Academy",
  "United States Naval Academy",
  "United States University",
  "United Talmudical Seminary",
  "United Tribes Technical College",
  "Unitek College",
  "Unity College",
  "Universal Career School",
  "Universal College of Healing Arts",
  "Universal Technical Institute of Arizona Inc",
  "Universal Technical Institute of California Inc",
  "Universal Technical Institute of Northern California Inc",
  "Universal Technical Institute-Dallas Fort Worth",
  "University at Buffalo",
  "University of Advancing Technology",
  "University of Akron Main Campus",
  "University of Akron Wayne College",
  "University of Alabama at Birmingham",
  "University of Alabama in Huntsville",
  "University of Alaska Anchorage",
  "University of Alaska Fairbanks",
  "University of Alaska Southeast",
  "University of Antelope Valley",
  "University of Arizona",
  "University of Arkansas",
  "University of Arkansas Community College Rich Mountain",
  "University of Arkansas Community College-Batesville",
  "University of Arkansas Community College-Hope",
  "University of Arkansas Community College-Morrilton",
  "University of Arkansas at Little Rock",
  "University of Arkansas at Monticello",
  "University of Arkansas at Pine Bluff",
  "University of Arkansas-Fort Smith",
  "University of Arkansas-Pulaski Technical College",
  "University of Baltimore",
  "University of Bridgeport",
  "University of California-Berkeley",
  "University of California-Davis",
  "University of California-Irvine",
  "University of California-Los Angeles",
  "University of California-Merced",
  "University of California-Riverside",
  "University of California-San Diego",
  "University of California-Santa Barbara",
  "University of California-Santa Cruz",
  "University of Central Arkansas",
  "University of Central Florida",
  "University of Central Missouri",
  "University of Central Oklahoma",
  "University of Charleston",
  "University of Chicago",
  "University of Cincinnati-Blue Ash College",
  "University of Cincinnati-Clermont College",
  "University of Cincinnati-Main Campus",
  "University of Colorado Boulder",
  "University of Colorado Colorado Springs",
  "University of Colorado Denver/Anschutz Medical Campus",
  "University of Connecticut",
  "University of Connecticut-Avery Point",
  "University of Connecticut-Hartford Campus",
  "University of Connecticut-Stamford",
  "University of Connecticut-Waterbury Campus",
  "University of Dallas",
  "University of Dayton",
  "University of Delaware",
  "University of Denver",
  "University of Detroit Mercy",
  "University of Dubuque",
  "University of Evansville",
  "University of Florida",
  "University of Florida-Online",
  "University of Fort Lauderdale",
  "University of Georgia",
  "University of Hartford",
  "University of Hawaii Maui College",
  "University of Hawaii at Hilo",
  "University of Hawaii at Manoa",
  "University of Hawaii-West Oahu",
  "University of Holy Cross",
  "University of Houston",
  "University of Houston-Clear Lake",
  "University of Houston-Downtown",
  "University of Houston-Victoria",
  "University of Idaho",
  "University of Illinois at Chicago",
  "University of Illinois at Springfield",
  "University of Illinois at Urbana-Champaign",
  "University of Indianapolis",
  "University of Iowa",
  "University of Jamestown",
  "University of Kansas",
  "University of Kentucky",
  "University of La Verne",
  "University of Louisiana at Lafayette",
  "University of Louisiana at Monroe",
  "University of Louisville",
  "University of Lynchburg",
  "University of Maine",
  "University of Maine at Augusta",
  "University of Maine at Farmington",
  "University of Maine at Fort Kent",
  "University of Maine at Machias",
  "University of Maine at Presque Isle",
  "University of Management and Technology",
  "University of Mary",
  "University of Mary Hardin-Baylor",
  "University of Mary Washington",
  "University of Maryland Eastern Shore",
  "University of Maryland-Baltimore County",
  "University of Maryland-College Park",
  "University of Maryland-University College",
  "University of Massachusetts-Amherst",
  "University of Massachusetts-Boston",
  "University of Massachusetts-Dartmouth",
  "University of Massachusetts-Lowell",
  "University of Memphis",
  "University of Miami",
  "University of Michigan-Ann Arbor",
  "University of Michigan-Dearborn",
  "University of Michigan-Flint",
  "University of Minnesota-Crookston",
  "University of Minnesota-Duluth",
  "University of Minnesota-Morris",
  "University of Minnesota-Rochester",
  "University of Minnesota-Twin Cities",
  "University of Mississippi",
  "University of Missouri-Columbia",
  "University of Missouri-Kansas City",
  "University of Missouri-St Louis",
  "University of Mobile",
  "University of Montevallo",
  "University of Mount Olive",
  "University of Mount Union",
  "University of Nebraska at Kearney",
  "University of Nebraska at Omaha",
  "University of Nebraska-Lincoln",
  "University of Nevada-Las Vegas",
  "University of Nevada-Reno",
  "University of New England",
  "University of New Hampshire at Manchester",
  "University of New Hampshire-Main Campus",
  "University of New Haven",
  "University of New Mexico-Gallup Campus",
  "University of New Mexico-Los Alamos Campus",
  "University of New Mexico-Main Campus",
  "University of New Mexico-Taos Campus",
  "University of New Mexico-Valencia County Campus",
  "University of New Orleans",
  "University of North Alabama",
  "University of North Carolina School of the Arts",
  "University of North Carolina Wilmington",
  "University of North Carolina at Asheville",
  "University of North Carolina at Chapel Hill",
  "University of North Carolina at Charlotte",
  "University of North Carolina at Greensboro",
  "University of North Carolina at Pembroke",
  "University of North Dakota",
  "University of North Florida",
  "University of North Georgia",
  "University of North Texas",
  "University of North Texas at Dallas",
  "University of Northern Colorado",
  "University of Northern Iowa",
  "University of Northwestern Ohio",
  "University of Northwestern-St Paul",
  "University of Notre Dame",
  "University of Oklahoma-Norman Campus",
  "University of Oregon",
  "University of Pennsylvania",
  "University of Phoenix-Arizona",
  "University of Phoenix-California",
  "University of Phoenix-Hawaii",
  "University of Phoenix-Nevada",
  "University of Phoenix-Texas",
  "University of Phoenix-Virginia",
  "University of Pikeville",
  "University of Pittsburgh-Bradford",
  "University of Pittsburgh-Greensburg",
  "University of Pittsburgh-Johnstown",
  "University of Pittsburgh-Pittsburgh Campus",
  "University of Pittsburgh-Titusville",
  "University of Portland",
  "University of Providence",
  "University of Puget Sound",
  "University of Redlands",
  "University of Rhode Island",
  "University of Richmond",
  "University of Rio Grande",
  "University of Rochester",
  "University of Saint Francis-Fort Wayne",
  "University of Saint Joseph",
  "University of Saint Katherine",
  "University of Saint Mary",
  "University of San Diego",
  "University of San Francisco",
  "University of Science and Arts of Oklahoma",
  "University of Scranton",
  "University of Sioux Falls",
  "University of South Alabama",
  "University of South Carolina-Aiken",
  "University of South Carolina-Beaufort",
  "University of South Carolina-Columbia",
  "University of South Carolina-Lancaster",
  "University of South Carolina-Salkehatchie",
  "University of South Carolina-Sumter",
  "University of South Carolina-Union",
  "University of South Carolina-Upstate",
  "University of South Dakota",
  "University of South Florida-Main Campus",
  "University of South Florida-Sarasota-Manatee",
  "University of South Florida-St Petersburg",
  "University of Southern California",
  "University of Southern Indiana",
  "University of Southern Maine",
  "University of Southern Mississippi",
  "University of St Francis",
  "University of St Thomas",
  "University of St Thomas",
  "University of Toledo",
  "University of Tulsa",
  "University of Utah",
  "University of Valley Forge",
  "University of Vermont",
  "University of Virginia-Main Campus",
  "University of Washington-Bothell Campus",
  "University of Washington-Seattle Campus",
  "University of Washington-Tacoma Campus",
  "University of West Alabama",
  "University of West Georgia",
  "University of Wisconsin Colleges",
  "University of Wisconsin-Eau Claire",
  "University of Wisconsin-Green Bay",
  "University of Wisconsin-La Crosse",
  "University of Wisconsin-Madison",
  "University of Wisconsin-Milwaukee",
  "University of Wisconsin-Oshkosh",
  "University of Wisconsin-Parkside",
  "University of Wisconsin-Platteville",
  "University of Wisconsin-River Falls",
  "University of Wisconsin-Stevens Point",
  "University of Wisconsin-Stout",
  "University of Wisconsin-Superior",
  "University of Wisconsin-Whitewater",
  "University of Wyoming",
  "University of the Cumberlands",
  "University of the District of Columbia",
  "University of the Incarnate Word",
  "University of the Ozarks",
  "University of the Pacific",
  "University of the People",
  "University of the Potomac-VA Campus",
  "University of the Potomac-Washington DC Campus",
  "University of the Sciences",
  "University of the Southwest",
  "University of the West",
  "Upper Iowa University",
  "Urban College of Boston",
  "Urbana University",
  "Ursinus College",
  "Ursuline College",
  "Uta Mesivta of Kiryas Joel",
  "Utah State University",
  "Utah Valley University",
  "Utica College",
  "Valdosta State University",
  "Valencia College",
  "Valley City State University",
  "Valley College of Medical Careers",
  "Valley College-Martinsburg",
  "Valley Forge Military College",
  "Valor Christian College",
  "Valparaiso University",
  "Vance-Granville Community College",
  "VanderCook College of Music",
  "Vanderbilt University",
  "Vanguard University of Southern California",
  "Vassar College",
  "Vatterott College-Appling Farms",
  "Vatterott College-Berkeley",
  "Vatterott College-Dividend",
  "Vatterott College-Fairview Heights",
  "Vatterott College-Joplin",
  "Vatterott College-Kansas City",
  "Vatterott College-Quincy",
  "Vatterott College-Springfield",
  "Vatterott College-St Charles",
  "Vatterott College-Sunset Hills",
  "Vatterott College-Tulsa",
  "Vatterott College-ex'treme Institute by Nelly-St Louis",
  "Vaughn College of Aeronautics and Technology",
  "Ventura College",
  "Veritas Baptist College",
  "Vermilion Community College",
  "Vermont Technical College",
  "Vernon College",
  "Vet Tech Institute",
  "Vet Tech Institute of Houston",
  "Victor Valley College",
  "Victoria College",
  "Villa Maria College",
  "Villanova University",
  "Vincennes University",
  "Virginia College-Augusta",
  "Virginia College-Birmingham",
  "Virginia College-Charleston",
  "Virginia College-Florence",
  "Virginia College-Greenville",
  "Virginia College-Jacksonville",
  "Virginia College-Knoxville",
  "Virginia College-Lubbock",
  "Virginia College-Richmond",
  "Virginia College-Savannah",
  "Virginia Commonwealth University",
  "Virginia Highlands Community College",
  "Virginia International University",
  "Virginia Military Institute",
  "Virginia Polytechnic Institute and State University",
  "Virginia State University",
  "Virginia Union University",
  "Virginia University of Lynchburg",
  "Virginia Wesleyan University",
  "Virginia Western Community College",
  "Visible Music College",
  "Vista College",
  "Vista College-Online",
  "Viterbo University",
  "Volunteer State Community College",
  "Voorhees College",
  "W L Bonner College",
  "Wabash College",
  "Wabash Valley College",
  "Wade College",
  "Wagner College",
  "Wake Forest University",
  "Wake Technical Community College",
  "Walden University",
  "Waldorf University",
  "Walla Walla Community College",
  "Walla Walla University",
  "Walsh University",
  "Walters State Community College",
  "Warner Pacific University",
  "Warner Pacific University Professional and Graduate Studies",
  "Warner University",
  "Warren County Community College",
  "Warren Wilson College",
  "Wartburg College",
  "Washburn University",
  "Washington & Jefferson College",
  "Washington Adventist University",
  "Washington College",
  "Washington County Community College",
  "Washington State Community College",
  "Washington State University",
  "Washington University in St Louis",
  "Washington and Lee University",
  "Washtenaw Community College",
  "Watkins College of Art Design & Film",
  "Waubonsee Community College",
  "Waukesha County Technical College",
  "Wave Leadership College",
  "Wayland Baptist University",
  "Wayne Community College",
  "Wayne County Community College District",
  "Wayne State College",
  "Wayne State University",
  "Waynesburg University",
  "Weatherford College",
  "Webb Institute",
  "Webber International University",
  "Weber State University",
  "Webster University",
  "Welch College",
  "WellSpring School of Allied Health-Kansas City",
  "WellSpring School of Allied Health-Lawrence",
  "WellSpring School of Allied Health-Springfield",
  "Wellesley College",
  "Wells College",
  "Wenatchee Valley College",
  "Wentworth Institute of Technology",
  "Wesley College",
  "Wesleyan College",
  "Wesleyan University",
  "West Chester University of Pennsylvania",
  "West Coast Ultrasound Institute",
  "West Coast University-Dallas",
  "West Coast University-Los Angeles",
  "West Coast University-Miami",
  "West Coast University-Ontario",
  "West Coast University-Orange County",
  "West Georgia Technical College",
  "West Hills College-Coalinga",
  "West Hills College-Lemoore",
  "West Kentucky Community and Technical College",
  "West Liberty University",
  "West Los Angeles College",
  "West Shore Community College",
  "West Texas A & M University",
  "West Valley College",
  "West Virginia Junior College-Bridgeport",
  "West Virginia Junior College-Charleston",
  "West Virginia Junior College-Morgantown",
  "West Virginia Northern Community College",
  "West Virginia State University",
  "West Virginia University",
  "West Virginia University Institute of Technology",
  "West Virginia University at Parkersburg",
  "West Virginia Wesleyan College",
  "Westcliff University",
  "Western Carolina University",
  "Western Connecticut State University",
  "Western Dakota Technical Institute",
  "Western Governors University",
  "Western Illinois University",
  "Western International University",
  "Western Iowa Tech Community College",
  "Western Kentucky University",
  "Western Michigan University",
  "Western Nebraska Community College",
  "Western Nevada College",
  "Western New England University",
  "Western New Mexico University",
  "Western Oklahoma State College",
  "Western Oregon University",
  "Western Piedmont Community College",
  "Western State Colorado University",
  "Western Technical College",
  "Western Technical College",
  "Western Technical College",
  "Western Texas College",
  "Western Washington University",
  "Western Wyoming Community College",
  "Westfield State University",
  "Westminster College",
  "Westminster College",
  "Westminster College",
  "Westmont College",
  "Westmoreland County Community College",
  "Wharton County Junior College",
  "Whatcom Community College",
  "Wheaton College",
  "Wheaton College",
  "Wheeling Jesuit University",
  "Wheelock College",
  "White Earth Tribal and Community College",
  "White Mountains Community College",
  "Whitman College",
  "Whittier College",
  "Whitworth University",
  "Wichita State University",
  "Wichita State University-Campus of Applied Sciences and Technology",
  "Wichita Technical Institute",
  "Widener University",
  "Wilberforce University",
  "Wiley College",
  "Wilkes Community College",
  "Wilkes University",
  "Willamette University",
  "William Carey University",
  "William Jessup University",
  "William Jewell College",
  "William Moore College of Technology",
  "William Paterson University of New Jersey",
  "William Peace University",
  "William Penn University",
  "William Rainey Harper College",
  "William Woods University",
  "Williams Baptist University",
  "Williams College",
  "Williamsburg Technical College",
  "Williamson Christian College",
  "Williamson College of the Trades",
  "Williston State College",
  "Wilmington College",
  "Wilmington University",
  "Wilson College",
  "Wilson Community College",
  "Windward Community College",
  "Wingate University",
  "Winona State University",
  "Winston-Salem State University",
  "Winthrop University",
  "Wiregrass Georgia Technical College",
  "Wisconsin Indianhead Technical College",
  "Wisconsin Lutheran College",
  "Wittenberg University",
  "Wofford College",
  "Women's Institute of Torah Seminary",
  "Woodbury University",
  "Woodland Community College",
  "Wor-Wic Community College",
  "Worcester Polytechnic Institute",
  "Worcester State University",
  "World Mission University",
  "Wright State University-Lake Campus",
  "Wright State University-Main Campus",
  "WyoTech",
  "Wytheville Community College",
  "Xavier University",
  "Xavier University of Louisiana",
  "YTI Career Institute-Altoona",
  "YTI Career Institute-York",
  "Yakima Valley College",
  "Yale University",
  "Yavapai College",
  "Yeshiva Bais Aharon",
  "Yeshiva Chemdas Hatorah",
  "Yeshiva College of the Nations Capital",
  "Yeshiva D'monsey Rabbinical College",
  "Yeshiva Derech Chaim",
  "Yeshiva Gedola Ohr Yisrael",
  "Yeshiva Gedola Tiferes Yerachmiel",
  "Yeshiva Gedolah Imrei Yosef D'spinka",
  "Yeshiva Gedolah Keren Hatorah",
  "Yeshiva Gedolah Kesser Torah",
  "Yeshiva Gedolah Shaarei Shmuel",
  "Yeshiva Gedolah Tiferes Boruch",
  "Yeshiva Gedolah Zichron Leyma",
  "Yeshiva Gedolah of Cliffwood",
  "Yeshiva Gedolah of Greater Detroit",
  "Yeshiva Karlin Stolin",
  "Yeshiva Kollel Tifereth Elizer",
  "Yeshiva Ohr Elchonon Chabad West Coast Talmudical Seminary",
  "Yeshiva Ohr Naftoli",
  "Yeshiva Shaar Ephraim",
  "Yeshiva Shaar Hatorah",
  "Yeshiva Shaarei Torah of Rockland",
  "Yeshiva Sholom Shachna",
  "Yeshiva Toras Chaim",
  "Yeshiva University",
  "Yeshiva Yesodei Hatorah",
  "Yeshiva Zichron Aryeh",
  "Yeshiva of Far Rockaway Derech Ayson Rabbinical Seminary",
  "Yeshiva of Machzikai Hadas",
  "Yeshiva of Nitra Rabbinical College",
  "Yeshiva of the Telshe Alumni",
  "Yeshivah Gedolah Rabbinical College",
  "Yeshivas Be'er Yitzchok",
  "Yeshivas Emek Hatorah",
  "Yeshivas Maharit D'Satmar",
  "Yeshivas Novominsk",
  "Yeshivath Beth Moshe",
  "Yeshivath Viznitz",
  "Yeshivath Zichron Moshe",
  "York College",
  "York College of Pennsylvania",
  "York County Community College",
  "York Technical College",
  "Young Harris College",
  "Youngstown State University",
  "Yuba College",
  "Zane State College",
  "Zaytuna College",
];

export default universityList;
