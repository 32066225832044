import React from "react";
import { Button, Tag, Modal, Input, Pagination } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import Loading from "../../../Components/Loading";
import { execGetBlog, execDeleteBlog, execPinBlog, execBlogUpdate } from "../../../api/apiCalls";
import { Link } from "react-router-dom";
import { getRoleColor } from "../../../config";
import { notifySuccess, notifyError } from "../../../Helpers/notification";
import { sortOn } from "../../../Helpers/sort";

export default function Blogs() {
  const [state, setState] = React.useState({
    loading: true,
    data: null,
    error: false,
  });

  const [sortTitle, setSortTitle] = React.useState(false);

  const titleSort = () => {
    let newData = [...state.data];
    const val = !sortTitle;
    if (val) {
      newData.sort(sortOn("title"));
    }
    setFormattedData(newData);
    setSortTitle(val);
  };

  const [formattedData, setFormattedData] = React.useState([]);
  const [paginate, setPaginate] = React.useState({ total: 0, page: 1, per_page: 20, total_page: 0 });

  const fetchUsers = async (q = "", page = false, per_page = false) => {
    setState({ ...state, loading: true });
    try {
      const res = await execGetBlog(q, page || paginate.page, per_page || paginate.per_page);
      setState({ loading: false, data: res.data, error: false });
      setPaginate({ total: res.total, page: res.page, per_page: res.per_page, total_page: res.total_page });
      setFormattedData(res.data);
    } catch (err) {
      setState({ loading: false, data: null, error: err });
    }
  };

  React.useEffect(() => {
    fetchUsers();
    //eslint-disable-next-line
  }, []);

  return (
    <section id="contents">
      <br />
      <div className="flex jcsb">
        <h1 className="title"> Blogs </h1>
        <Link to="/admin/add_blog">
          <Button type="primary" icon={<PlusOutlined />}>
            Add Blog
          </Button>
        </Link>
      </div>
      <br />
      <div className="flex jcsb">
        <div className="res-search">
          <Input.Search
            placeholder="Search.."
            onSearch={(val) => {
              fetchUsers(val, 1);
            }}
            onChange={(e) => {
              const val = e.target.value;
              if (!val || val.length === 0) {
                fetchUsers("", 1);
              }
            }}
            allowClear
          ></Input.Search>
        </div>
        <Pagination
          onChange={(val) => {
            fetchUsers("", val);
          }}
          total={paginate.total}
          current={paginate.page}
          showSizeChanger
          pageSize={paginate.per_page}
          onShowSizeChange={(val, perPage) => {
            fetchUsers("", val, perPage);
          }}
        />
      </div>
      <br />
      {state.loading && <Loading />}
      {state.error && <p style={{ color: "red" }}>{JSON.stringify(state.error)}</p>}
      {state.data && formattedData.length > 0 && (
        <React.Fragment>
          <div className="contents-table">
            <table cellPadding="0" cellSpacing="0">
              <ContentTableHead titleSort={titleSort} titleSortMode={sortTitle} />
              <tbody>
                {formattedData.map((item, index) => {
                  return <ContentTableItems refresh={fetchUsers} key={item.id} {...item} sn={index + 1} />;
                })}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </section>
  );
}

const ContentTableHead = (props) => {
  return (
    <thead>
      <tr>
        <th style={{ maxWidth: "40px" }}>SN</th>
        <th style={{ cursor: "pointer" }} onClick={() => props.titleSort()}>
          Title
          {props.titleSortMode ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        </th>
        <th>User</th>
        <th>Likes</th>
        <th>Status</th>
        <th style={{ maxWidth: "200px" }}>Created At</th>
        <th style={{ maxWidth: "200px" }}>Pinned</th>
        <th style={{ maxWidth: "200px" }}>Actions</th>
      </tr>
    </thead>
  );
};

const ContentTableItems = (props) => {
  const onStatusChange = async () => {
    const status = props.status === "Pending" ? "Active" : "Pending";
    try {
      const res = await execBlogUpdate(props._id, { status });
      notifySuccess(res.message);
      props.refresh();
    } catch (err) {
      notifyError(err.message);
    }
  };

  const onDelete = () => {
    Modal.confirm({
      title: "Are you sure?",
      content: "you want to delete this blog",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await execDeleteBlog(props._id);
          notifySuccess(res.message);
          props.refresh();
        } catch (err) {
          notifyError(err.message);
        }
      },
    });
  };

  const onPin = async () => {
    try {
      const res = await execPinBlog(props._id);
      notifySuccess(res.message);
      props.refresh();
    } catch (err) {
      notifyError(err.message);
    }
  };

  return (
    <tr>
      <td>{props.sn}</td>
      <td>
        <div>
          <div className="flex jcc ci">
            <img src={props.cover_photo} className="blog-item-img" alt="" />
            <aside>
              <p>{props.title}</p>
            </aside>
          </div>
        </div>
      </td>
      <td>{(props.user && props.user.first_name + " " + props.user.last_name) || "n/a"}</td>
      <td>{props.like_count}</td>
      <td>
        <Tag color={getRoleColor(props.status)}>{props.status}</Tag>
        <Button shape="circle" onClick={onStatusChange} icon={<EditOutlined />} size="small" type="primary" />
      </td>
      <td>{props.created_at}</td>
      <td>
        {props.pinned ? <Tag color="geekblue">Yes</Tag> : <Tag color="red">No</Tag>}
        <Button shape="circle" onClick={() => onPin()} icon={<EditOutlined />} size="small" type="primary" />
      </td>
      <td className="actions">
        <div className="flex jcc ci">
          <Link to={"/admin/edit_blog/" + props._id}>
            <Button shape="circle" icon={<EditOutlined />} size="small" type="primary" />
          </Link>
          <div className="hgap"></div>
          <Button shape="circle" onClick={() => onDelete()} icon={<DeleteOutlined />} size="small" type="danger" />
        </div>
      </td>
    </tr>
  );
};
