import React from "react";
import { Button, Tag, Modal, Input, Pagination, InputNumber } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import Loading from "../../../Components/Loading";
import { execGetResource, execDeleteResource, execUpdateResource } from "../../../api/apiCalls";
import { getRoleColor } from "../../../config";
import { notifySuccess, notifyError } from "../../../Helpers/notification";
import EditBusiness from "./EditBusiness";
import { sortOn } from "../../../Helpers/sort";

export default function Resource() {
  const [showEdit, setShowEdit] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [state, setState] = React.useState({
    loading: true,
    data: null,
    error: false,
  });

  const [sortTitle, setSortTitle] = React.useState(false);

  const titleSort = () => {
    let newData = [...state.data];
    const val = !sortTitle;
    if (val) {
      newData.sort(sortOn("title"));
    }
    setFormattedData(newData);
    setSortTitle(val);
  };

  const [formattedData, setFormattedData] = React.useState([]);
  const [paginate, setPaginate] = React.useState({ total: 0, page: 1, per_page: 20, total_page: 0 });

  const fetchUsers = async (q = "", page = false, per_page = false) => {
    setState({ ...state, data: null, loading: true });
    try {
      const res = await execGetResource(q, page || paginate.page, per_page || paginate.per_page);
      console.log(res);
      setState({ loading: false, data: res.data, error: false });
      setPaginate({ total: res.total, page: res.page, per_page: res.per_page, total_page: res.total_page });
      setFormattedData(res.data);
    } catch (err) {
      console.log(err);
      setState({ loading: false, data: null, error: err });
    }
  };

  React.useEffect(() => {
    fetchUsers();
    //eslint-disable-next-line
  }, []);

  return (
    <section id="contents">
      <EditBusiness
        visible={showEdit}
        data={editData}
        onCancel={(refresh = false) => {
          setShowEdit(false);
          if (refresh === true) fetchUsers();
        }}
      />
      <br />
      <div className="flex jcsb">
        <h1 className="title"> Resource </h1>
      </div>
      <div className="flex jcsb">
        <div className="res-search">
          <Input.Search
            placeholder="Search.."
            onSearch={(val) => {
              fetchUsers(val, 1);
            }}
            onChange={(e) => {
              const val = e.target.value;
              if (!val || val.length === 0) {
                fetchUsers("", 1);
              }
            }}
            allowClear
          ></Input.Search>
        </div>
        <Pagination
          onChange={(val) => {
            fetchUsers("", val);
          }}
          total={paginate.total}
          current={paginate.page}
          showSizeChanger
          pageSize={paginate.per_page}
          onShowSizeChange={(val, perPage) => {
            fetchUsers("", val, perPage);
          }}
        />
      </div>
      <br />
      {state.loading && <Loading />}
      {state.error && <p style={{ color: "red" }}>{JSON.stringify(state.error)}</p>}
      {state.data && formattedData.length > 0 && (
        <React.Fragment>
          <div className="contents-table">
            <table cellPadding="0" cellSpacing="0">
              <ContentTableHead titleSort={titleSort} titleSortMode={sortTitle} />
              <tbody>
                {formattedData.map((item, index) => {
                  return (
                    <ContentTableItems
                      refresh={fetchUsers}
                      onEdit={() => {
                        setEditData(item);
                        setShowEdit(true);
                      }}
                      key={item._id}
                      {...item}
                      sn={index + 1}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </section>
  );
}

const ContentTableHead = (props) => {
  return (
    <thead>
      <tr>
        <th style={{ maxWidth: "40px" }}>SN</th>
        <th style={{ cursor: "pointer" }} onClick={() => props.titleSort()}>
          Title
          {props.titleSortMode ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        </th>
        <th>Link</th>
        <th>Status</th>
        <th>Priority</th>
        <th style={{ maxWidth: "200px" }}>Created At</th>
        <th style={{ maxWidth: "200px" }}>Actions</th>
      </tr>
    </thead>
  );
};

const ContentTableItems = (props) => {
  const [priority, setPriority] = React.useState(5000);
  const onDelete = () => {
    Modal.confirm({
      title: "Are you sure?",
      content: "you want to delete this resource",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await execDeleteResource(props._id);
          notifySuccess(res.message);
          props.refresh();
        } catch (err) {
          notifyError(err.message);
        }
      },
    });
  };

  const onPriorityChange = async (val) => {
    try {
      const res = await execUpdateResource(props._id, { priority: val });
      notifySuccess(res.message);
      props.refresh();
    } catch (err) {
      notifyError(err.message);
    }
  };

  const onStatusChange = async () => {
    const status = props.status === "Pending" ? "Active" : "Pending";
    try {
      const res = await execUpdateResource(props._id, { status });
      notifySuccess(res.message);
      props.refresh();
    } catch (err) {
      notifyError(err.message);
    }
  };

  React.useEffect(() => {
    if (props.priority) setPriority(props.priority);
  }, [props.priority]);

  return (
    <tr>
      <td>{props.sn}</td>
      <td>{props.title}</td>
      <td>{props.link}</td>
      <td>
        <Tag color={getRoleColor(props.status)}>{props.status}</Tag>
        <Button shape="circle" onClick={onStatusChange} icon={<EditOutlined />} size="small" type="primary" />
      </td>
      <td className="flex jcc ci">
        <InputNumber
          type="text"
          onKeyDown={(e) => {
            if (e.which === 13 && e.target.value) {
              e.preventDefault();
              onPriorityChange(e.target.value);
            }
          }}
          onChange={(val) => setPriority(val)}
          value={priority || "5000"}
          style={{ width: "70px" }}
          placeholder="eg. 1"
        />
        <div className="hgap"></div>
        <Button
          shape="circle"
          onClick={() => onPriorityChange(priority)}
          icon={<EditOutlined />}
          size="small"
          type="primary"
        />
      </td>
      <td>{props.created_at}</td>
      <td className="actions">
        <div className="flex jcc ci">
          <Button shape="circle" onClick={() => props.onEdit()} icon={<EditOutlined />} size="small" type="primary" />
          <div className="hgap"></div>
          <Button shape="circle" onClick={() => onDelete()} icon={<DeleteOutlined />} size="small" type="danger" />
        </div>
      </td>
    </tr>
  );
};
