import React from "react";
import { Menu, Layout } from "antd";
import logo from "../../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import { GoldOutlined, UsergroupAddOutlined, UserOutlined, PieChartOutlined } from "@ant-design/icons";

export default function DashboardSlider(props) {
  const [selectedItem, setSelectedItem] = React.useState("1");

  return (
    <Layout hasSider>
      <Layout.Sider
        theme="dark"
        trigger={null}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          if (broken) {
            props.setSliderCollapsed(true);
          } else {
            props.setSliderCollapsed(false);
          }
        }}
        onCollapse={() => {
          props.setSliderCollapsed(!props.sliderCollapsed);
        }}
        collapsedWidth={0}
        width={250}
        collapsed={props.sliderCollapsed}
        // collapsible={true}
      >
        <Menu style={{ minHeight: "100vh", fontWeight: "700" }} selectedKeys={[selectedItem]} mode="inline" theme="dark">
          <div className="logo">
            <img src={logo} alt="" />
          </div>

          <Menu.Item key="1">
            <NavLink
              to="/admin"
              isActive={(e) => {
                if (e && e.isExact) setSelectedItem("1");
              }}
            >
              <UsergroupAddOutlined />
              <span>Users</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="2">
            <NavLink
              to="/admin/blogs"
              isActive={(e) => {
                if (e && e.isExact) setSelectedItem("2");
              }}
            >
              <GoldOutlined />
              <span>Blogs</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="3">
            <NavLink
              to="/admin/professionals"
              isActive={(e) => {
                if (e && e.isExact) setSelectedItem("3");
              }}
            >
              <UserOutlined />
              <span>Professionals</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4">
            <NavLink
              to="/admin/business"
              isActive={(e) => {
                if (e && e.isExact) setSelectedItem("4");
              }}
            >
              <UserOutlined />
              <span>Organizations</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="5">
            <NavLink
              to="/admin/resource"
              isActive={(e) => {
                if (e && e.isExact) setSelectedItem("5");
              }}
            >
              <PieChartOutlined />
              <span>Resources</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="6">
            <NavLink
              to="/admin/testimonial"
              isActive={(e) => {
                if (e && e.isExact) setSelectedItem("6");
              }}
            >
              <PieChartOutlined />
              <span>Testimonial</span>
            </NavLink>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
    </Layout>
  );
}
