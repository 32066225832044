import { axiosInstance } from "./axiosInterceptor";

export const execLogin = (payload) => {
  return axiosInstance.post("/admin/login", payload);
};
export const execGetUsers = (q = "", page = 1, per_page = 50) => {
  return axiosInstance.get("/admin/users?q=" + q + "&page=" + page + "&per_page=" + per_page);
};
export const execGetUser = (userId) => {
  return axiosInstance.get("/admin/user/" + userId);
};
export const execGetBlog = (q, page, per_page) => {
  return axiosInstance.get("/blog?q=" + q + "&page=" + page + "&per_page=" + per_page);
};
export const execAddBlog = (payload) => {
  return axiosInstance.post("/admin/blog", payload);
};

export const execGetBlogById = (bId) => {
  return axiosInstance.get("/admin/blog/" + bId);
};

export const execBlogUpdate = (bId, payload) => {
  return axiosInstance.patch("/admin/blog/" + bId, payload);
};

export const execProfileUpdate = (bId, payload) => {
  return axiosInstance.patch("/admin/profile/" + bId, payload);
};

export const execFileUpload = (payload) => {
  return axiosInstance.post("/upload", payload);
};

export const execDeleteBlog = (id) => {
  return axiosInstance.delete("/admin/blog/" + id);
};

export const execGetPros = (q, page, per_page) => {
  return axiosInstance.get("/admin/professional?q=" + q + "&page=" + page + "&per_page=" + per_page);
};

export const execUpdatePros = (id, payload) => {
  return axiosInstance.patch("/admin/professional/" + id, payload);
};
export const execDeletePros = (id) => {
  return axiosInstance.delete("/admin/professional/" + id);
};

export const execGetBusiness = (q, page, per_page) => {
  return axiosInstance.get("/admin/business?q=" + q + "&page=" + page + "&per_page=" + per_page);
};

export const execUpdateBusiness = (id, payload) => {
  return axiosInstance.patch("/admin/business/" + id, payload);
};
export const execDeleteBusiness = (id) => {
  return axiosInstance.delete("/admin/business/" + id);
};
export const execPinBlog = (id) => {
  return axiosInstance.post("/admin/blog/pin/" + id);
};

export const execGetResource = (q, page, per_page) => {
  return axiosInstance.get("/admin/resource?q=" + q + "&page=" + page + "&per_page=" + per_page);
};
export const execUpdateResource = (id, payload) => {
  return axiosInstance.patch("/admin/resource/" + id, payload);
};
export const execDeleteResource = (id) => {
  return axiosInstance.delete("/admin/resource/" + id);
};

export const execDeleteUser = (id) => {
  return axiosInstance.delete("/admin/user/" + id);
};

export const execGetTestimonial = () => {
  return axiosInstance.get("/admin/testimonial");
};

export const execAddTestimonial = (payload) => {
  return axiosInstance.post("/admin/testimonial", payload);
};

export const execDeleteTestimonial = (id) => {
  return axiosInstance.delete("/admin/testimonial/" + id);
};

export const execUpdateTestimonial = (id, payload) => {
  return axiosInstance.patch("/admin/testimonial/" + id, payload);
};

export const execStoreUpdateKey = (payload) => {
  return axiosInstance.post("/admin/key", payload);
};
