import React from "react";
import { Modal, Input, Form, Upload, Button, Select } from "antd";
import { notifySuccess, notifyError } from "../../../Helpers/notification";
import { execAddTestimonial } from "../../../api/apiCalls";
import TextArea from "antd/lib/input/TextArea";
import { base_url } from "../../../api/axiosInterceptor";
import { UploadOutlined } from "@ant-design/icons";

export default function AddTestimonial(props) {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [businessLogo, setBusinessLogo] = React.useState(null);

  const onEdit = async (val) => {
    setLoading(true);
    try {
      const res = await execAddTestimonial({ ...val, image: businessLogo });
      setLoading(false);
      notifySuccess(res.message);
      props.onCancel(true);
    } catch (err) {
      setLoading(false);
      notifyError(err.message);
    }
  };

  const uploadDatas = {
    name: "file",
    action: base_url + "/upload",
    headers: {
      authorization: "Bearer " + localStorage.getItem("admin_token"),
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log(info.file.response);
        setBusinessLogo(info.file.response.url);
        notifySuccess(info.file.response.message);
      } else if (info.file.status === "error") {
        notifyError("File upload failed, looks like you are not logged in");
      }
    },
  };

  return (
    <div>
      <Modal
        title="Add Testimonial"
        visible={props.visible}
        maskClosable={false}
        centered
        onCancel={() => {
          props.onCancel();
        }}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{}}
      >
        <Form form={form} onFinish={onEdit}>
          {businessLogo && (
            <div className="flex jcc">
              <img style={{ width: 70, height: 70, borderRadius: 40 }} src={businessLogo} alt="" />
            </div>
          )}
          <br />
          <div className="flex jcc">
            <Upload {...uploadDatas} showUploadList={false}>
              <Button>
                <UploadOutlined /> Click to Upload Image
              </Button>
            </Upload>
          </div>
          <br />
          <Form.Item
            className="full"
            label="Display Side"
            name="side"
            rules={[
              {
                required: true,
                message: "Display side is required",
              },
            ]}
          >
            <Select placeholder="Select one side">
              <Select.Option value="Left">Left</Select.Option>
              <Select.Option value="Right">Right</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="full"
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Name is required",
              },
            ]}
          >
            <Input placeholder="eg. smth kaila" />
          </Form.Item>
          <Form.Item
            className="full"
            label="Work As"
            name="work_as"
            rules={[
              {
                required: true,
                message: "Work as is required",
              },
            ]}
          >
            <Input placeholder="eg. Student" />
          </Form.Item>
          <Form.Item
            className="full"
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Description is required",
              },
            ]}
          >
            <TextArea placeholder="eg. what he said about mangonepal? " />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
