import React from "react";
import { execGetUser, execProfileUpdate } from "../../../api/apiCalls";
import "./_userDetails.scss";
import { Tag, Switch, Button, Upload } from "antd";
import { getRoleColor } from "../../../config";
import Loading from "../../../Components/Loading";
import logo from "../../../assets/images/logo.png";
import EditGeneralDetails from "./EditGeneralDetails";
import EditLocation from "./EditLocation";
import EditMentoring from "./EditMentorship";
import EditEducation from "./EditEducation";
import { notifySuccess, notifyError } from "../../../Helpers/notification";
import { base_url } from "../../../api/axiosInterceptor";
import { UploadOutlined } from "@ant-design/icons";

const privacyNames = {
  name: "share_name",
  email: "share_email",
  phone: "share_phone",
};

export default function UserDetails({ match }) {
  const userId = match.params.id;

  const [showEditGeneralDetails, setShowEditGeneralDetails] = React.useState(false);
  const [showLocation, setShowLocation] = React.useState(false);
  const [showEducation, setShowEducation] = React.useState(false);
  const [showMentoring, setShowMentoring] = React.useState(false);

  const [state, setState] = React.useState({
    loading: false,
    data: null,
    error: false,
  });

  const updatePrivacy = async (key, value) => {
    const payload = {
      privacy: {
        [key]: value,
      },
    };
    try {
      const res = await execProfileUpdate(state.data._id, payload);
      notifySuccess(res.message);
    } catch (err) {
      notifyError(err.message);
    }
  };

  const verifyProfile = async (value) => {
    const payload = {
      verify: {
        is_verified: value,
      },
    };
    try {
      const res = await execProfileUpdate(state.data._id, payload);
      notifySuccess(res.message);
    } catch (err) {
      notifyError(err.message);
    }
  };

  const fetchUser = async () => {
    setState({ ...state, loading: true });
    try {
      const res = await execGetUser(userId);
      setState({ loading: false, error: false, data: res });
    } catch (err) {
      setState({ loading: false, error: err, data: null });
    }
  };

  const updateDp = async (url) => {
    const payload = { image: url };
    try {
      const res = await execProfileUpdate(state.data._id, payload);
      fetchUser();
      notifySuccess(res.message);
    } catch (err) {
      notifyError(err.message);
    }
  };

  const uploadDatas = {
    name: "file",
    action: base_url + "/upload",
    headers: {
      authorization: "Bearer " + localStorage.getItem("admin_token"),
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        updateDp(info.file.response.url);
      } else if (info.file.status === "error") {
        notifyError("File upload failed, looks like you are not logged in");
      }
    },
  };

  React.useEffect(() => {
    fetchUser();
    //eslint-disable-next-line
  }, []);

  return (
    <section id="user-details">
      <EditGeneralDetails
        visible={showEditGeneralDetails}
        data={state.data}
        onCancel={(refresh = false) => {
          setShowEditGeneralDetails(false);
          if (refresh === true) fetchUser();
        }}
      />
      <EditMentoring
        visible={showMentoring}
        data={state.data}
        onCancel={(refresh = false) => {
          setShowMentoring(false);
          if (refresh === true) fetchUser();
        }}
      />
      <EditLocation
        visible={showLocation}
        data={state.data}
        onCancel={(refresh = false) => {
          setShowLocation(false);
          if (refresh === true) fetchUser();
        }}
      />
      <EditEducation
        visible={showEducation}
        data={state.data}
        onCancel={(refresh = false) => {
          setShowEducation(false);
          if (refresh === true) fetchUser();
        }}
      />
      <br />
      <header className="flex jcsb">
        <h1 className="title">User Details</h1>
        <Button type="primary" onClick={() => setShowEditGeneralDetails(true)}>
          Edit General Details
        </Button>
      </header>
      {state.loading && <Loading />}
      {state.data && (
        <React.Fragment>
          <div className="card">
            <div className="names flex ci">
              <img src={state.data.image || logo} alt="" />
              <div className="name">
                <h3>
                  {state.data.first_name} {state.data.last_name}
                </h3>
                <p>{state.data.email}</p>
              </div>
            </div>
            <br />
            <Upload {...uploadDatas} showUploadList={false}>
              <Button>
                <UploadOutlined /> Upload Picture
              </Button>
            </Upload>
            <br />
            <br />
            <h4>
              <strong>
                Short Bio: <br />
              </strong>
            </h4>
            <p>{state.data.short_bio}</p>
            <br />
            <div className="user-details-list">
              <h3>
                <strong>Role: </strong>
                <Tag color={getRoleColor(state.data.role)}>{state.data.role}</Tag>
              </h3>

              <h3>
                <strong>Gender:</strong> {state.data.gender}
              </h3>
              <h3>
                <strong>Age Range:</strong> {state.data.age_range || "n/a"}
              </h3>
              <h3>
                <strong>Phone Number: </strong>
                {state.data.phone_number || "n/a"}
              </h3>
              <h3>
                <strong>Google: </strong>
                {state.data.google ? <Tag color="blue">True</Tag> : <Tag color="volcano">False</Tag>}
              </h3>
            </div>
          </div>
          <br />
          <header>
            <h1 className="title">Privacy</h1>
          </header>
          <div className="card">
            <div className="flex ci">
              <p>
                <strong>Email Address Verified? </strong>
              </p>
              <div className="hgap"></div>
              <Switch
                onChange={(val) => verifyProfile(val)}
                size="small"
                disabled={state.data.verify && state.data.verify.is_verified}
                defaultChecked={state.data.verify && state.data.verify.is_verified}
              />
            </div>

            <div className="flex ci">
              <p>
                <strong>Share your name publicly on this website?</strong>
              </p>
              <div className="hgap"></div>
              <Switch
                onChange={(val) => updatePrivacy(privacyNames.name, val)}
                size="small"
                defaultChecked={state.data.privacy && state.data.privacy.share_name}
              />
            </div>
            <div className="flex ci">
              <p>
                <strong>Share your email publicly on this website?</strong>
              </p>
              <div className="hgap"></div>
              <Switch
                onChange={(val) => updatePrivacy(privacyNames.email, val)}
                size="small"
                defaultChecked={state.data.privacy && state.data.privacy.share_email}
              />
            </div>
            <div className="flex ci">
              <p>
                <strong>Share your phone number publicly on this website?</strong>
              </p>
              <div className="hgap"></div>
              <Switch
                onChange={(val) => updatePrivacy(privacyNames.phone, val)}
                size="small"
                defaultChecked={state.data.privacy && state.data.privacy.share_phone}
              />
            </div>
          </div>
          <br />
          <header className="flex jcsb">
            <h1 className="title">Location</h1>
            <Button type="primary" onClick={() => setShowLocation(true)}>
              Edit Location
            </Button>
          </header>
          <div className="card">
            <div className="user-details-list">
              <h3>
                <strong>Home Town: </strong>
                {state.data.home_town || "n/a"}
              </h3>
              <h3>
                <strong>City: </strong>
                {(state.data.location && state.data.location.city) || "n/a"}
              </h3>
              <h3>
                <strong>State: </strong>
                {(state.data.location && state.data.location.state) || "n/a"}
              </h3>
              <h3>
                <strong>Zip Code: </strong>
                {(state.data.location && state.data.location.zip) || "n/a"}
              </h3>
            </div>
          </div>
          <br />
          <header className="flex jcsb">
            <h1 className="title">Mentorship</h1>
            <Button type="primary" onClick={() => setShowMentoring(true)}>
              Edit Mentorship
            </Button>
          </header>
          <div className="card">
            <div className="user-details-list">
              <h3>
                <strong>Mentoring In: </strong>
                {(state.data.education && state.data.education.mentoring_in) || "n/a"}
              </h3>
              <h3>
                <strong>Available: </strong>
                {state.data.education && state.data.education.become_mentor ? (
                  <Tag color="blue">Yes</Tag>
                ) : (
                  <Tag color="volcano">No</Tag>
                )}
              </h3>
              <h3>
                <strong>Need Mentoring In: </strong>
                {(state.data.education && state.data.education.need_mentoring_in) || "n/a"}
              </h3>
              <h3>
                <strong>Needed: </strong>
                {state.data.education && state.data.education.need_mentoring ? (
                  <Tag color="blue">Yes</Tag>
                ) : (
                  <Tag color="volcano">No</Tag>
                )}
              </h3>
            </div>
          </div>
          <br />
          <header className="flex jcsb">
            <h1 className="title">Education</h1>
            <Button type="primary" onClick={() => setShowEducation(true)}>
              Edit Education
            </Button>
          </header>

          <div className="card">
            <div className="user-details-list">
              <h3>
                <strong>University: </strong>
                {(state.data.education && state.data.education.university) || "n/a"}
              </h3>
              <h3>
                <strong>Level: </strong>
                {(state.data.education && state.data.education.level) || "n/a"}
              </h3>
              <h3>
                <strong>Major: </strong>
                {(state.data.education && state.data.education.major) || "n/a"}
              </h3>
              <h3>
                <strong>Concentrations: </strong>
                {(state.data.education && state.data.education.concentrations) || "n/a"}
              </h3>
              <h3>
                <strong>Graduated: </strong>
                {state.data.education && state.data.education.graduated ? (
                  <Tag color="blue">True</Tag>
                ) : (
                  <Tag color="volcano">False</Tag>
                )}
              </h3>
              <h3>
                <strong>Graduated Year: </strong>
                {(state.data.education && state.data.education.graduation_year) || "n/a"}
              </h3>
            </div>
          </div>
        </React.Fragment>
      )}
    </section>
  );
}
