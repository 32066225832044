import React from "react";
import { Input, Button, Form, Select } from "antd";
import { notifyError, notifySuccess } from "../../../Helpers/notification";
import { execFileUpload, execGetBlogById, execBlogUpdate } from "../../../api/apiCalls";
import Loading from "../../../Components/Loading";
import CkEditor from "../../../Components/CKeditor/CKeditor";

export default function EditBlog({ history, match }) {
  const [fileChange, setFileChange] = React.useState(null);
  const [fileUploaded, setFileUploaded] = React.useState({});
  const [fileUploadLoading, setFileUploadLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [body, setBody] = React.useState("");
  const [form] = Form.useForm();

  const [state, setState] = React.useState({
    loading: false,
    hasData: false,
    error: false,
  });
  const fileRef = React.createRef();

  const bId = match.params.slug;

  const fetchBlogDetails = async () => {
    setState({ ...state, loading: true });
    try {
      const res = await execGetBlogById(bId);
      setFileUploaded(res.cover_photo);
      form.setFieldsValue({ title: res.title, status: res.status });
      setBody(res.body);
      setState({ loading: false, hasData: true, error: false });
    } catch (err) {
      setState({ loading: false, hasData: false, error: err });
      notifyError(err);
    }
  };

  React.useEffect(() => {
    fetchBlogDetails();
    //eslint-disable-next-line
  }, []);

  const addFile = () => {
    const data = new FormData();
    data.append("file", fileChange);
    data.append("file_for", "BLOG_COVER");
    setFileUploadLoading(true);
    execFileUpload(data)
      .then((res) => {
        setFileUploaded({ id: res.id, url: res.url });
        setFileUploadLoading(false);
        notifySuccess(res.message);
      })
      .catch((err) => {
        setFileUploadLoading(false);
        notifyError(err.message);
      });
  };

  React.useEffect(() => {
    if (fileChange !== null) {
      addFile();
    }
    //eslint-disable-next-line
  }, [fileChange]);

  const updateBlog = async (value) => {
    let payload;
    if (!fileUploaded || !fileUploaded.id) {
      payload = { ...value, body };
    } else {
      payload = { ...value, body, cover_photo: fileUploaded.id };
    }

    setLoading(true);
    try {
      const res = await execBlogUpdate(bId, payload);
      notifySuccess(res.message);
      history.push("/admin/blogs");
    } catch (err) {
      notifyError(err.message);
      setLoading(false);
    }
  };

  return (
    <section id="contents">
      <br />

      <h1 className="title"> Update Blog </h1>
      <input
        type="file"
        onChange={(e) => {
          setFileChange(e.target.files[0]);
        }}
        ref={fileRef}
        style={{ display: "none" }}
      />

      <br />

      {state.loading && <Loading />}
      {state.hasData && (
        <React.Fragment>
          <div className="inputs">
            <Form form={form} onFinish={updateBlog}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                  gridGap: 15,
                }}
              >
                <aside>
                  <div
                    className="cover"
                    style={{
                      background: "#fff",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      height: 150,
                      boxShadow: "var(--shadow)",
                      borderRadius: 4,
                      overflow: "hidden",
                    }}
                  >
                    {fileUploaded.id && (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={fileUploaded.url}
                        alt=""
                      />
                    )}
                  </div>
                  <br />
                  <Button
                    onClick={() => {
                      setFileUploaded({});
                    }}
                    type="primary"
                  >
                    Clear Cover
                  </Button>
                </aside>
                <aside>
                  <Form.Item
                    label="Title"
                    name="title"
                    className="full"
                    rules={[
                      {
                        required: true,
                        message: "Title is required",
                      },
                    ]}
                  >
                    <Input placeholder="eg. this is a blog" />
                  </Form.Item>
                  <Form.Item
                    label="Status"
                    name="status"
                    className="full"
                    rules={[
                      {
                        required: true,
                        message: "Status is required",
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option value="Active">Active</Select.Option>
                      <Select.Option value="Pending">Pending</Select.Option>
                    </Select>
                  </Form.Item>
                  <div className="gap"></div>
                  <Button
                    type="primary"
                    loading={fileUploadLoading}
                    onClick={() => {
                      fileRef.current.click();
                    }}
                  >
                    Upload Cover Photo
                  </Button>

                  <div className="hgap"></div>
                  <Button htmlType="submit" loading={loading} type="primary">
                    Update Blog
                  </Button>
                </aside>
              </div>
            </Form>
            <br />
          </div>
          <div className="add-editor">
            <CkEditor
              onChange={(value) => {
                setBody(value);
              }}
              body={body}
            />
          </div>
        </React.Fragment>
      )}
    </section>
  );
}
