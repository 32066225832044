const configs = {
  SYSTEM_NAME: "Mango Nepal",
};

export const getRoleColor = (role) => {
  if (role === "Admin") return "red";
  if (role === "User") return "processing";
  if (role === "Pending") return "red";
  if (role === "Active") return "geekblue";
  return "processing";
};

export default configs;
