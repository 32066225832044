import React from "react";
import { Modal, Input, Form, Select } from "antd";
import { notifySuccess, notifyError } from "../../../Helpers/notification";
import { execUpdateResource } from "../../../api/apiCalls";

export default function EditBusiness(props) {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const onEdit = async (val) => {
    setLoading(true);
    try {
      const res = await execUpdateResource(props.data._id, val);
      setLoading(false);
      notifySuccess(res.message);
      props.onCancel(true);
    } catch (err) {
      setLoading(false);
      notifyError(err.message);
    }
  };

  React.useEffect(() => {
    form.setFieldsValue({ ...props.data });
    // eslint-disable-next-line
  }, [props.data]);

  return (
    <div>
      <Modal
        title="Edit Resource"
        visible={props.visible}
        maskClosable={false}
        centered
        onCancel={() => {
          props.onCancel();
        }}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{}}
      >
        <Form form={form} onFinish={onEdit}>
          <Form.Item
            className="full"
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Title is required",
              },
            ]}
          >
            <Input placeholder="eg. smth" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Status is required",
              },
            ]}
            className="full"
            label="Status"
            name="status"
          >
            <Select placeholder="Select one">
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Pending">Pending</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="full"
            label="Link"
            name="link"
            rules={[
              {
                required: true,
                message: "Link is required",
              },
            ]}
          >
            <Input placeholder="eg. http://google.com" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
