import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import Login from "./Views/Login/Login";
import "antd/dist/antd.min.css";
import "./styles/index.scss";
import Dashboard from "./Views/Admin/Dashboard/Dashboard";
import { getDetailsFromLocalStorage } from "./Helpers/helper";

export default function App() {
  const data = getDetailsFromLocalStorage();

  return (
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Route path="/login" exact component={Login} />
        <Route path="/" exact>
          {data.token && <Redirect to="/admin" />}
          {!data.token && <Redirect to="/login" />}
        </Route>
        <Route path="/admin" component={Dashboard} />
      </BrowserRouter>
    </React.Fragment>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
