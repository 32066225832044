import React from "react";
import { Button, Tag, Modal, Input, Pagination } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import Loading from "../../../Components/Loading";
import { execDeletePros, execGetPros, execUpdatePros } from "../../../api/apiCalls";
import { getRoleColor } from "../../../config";
import { notifySuccess, notifyError } from "../../../Helpers/notification";
import EditProfessional from "./EditProfessional";
import { sortOn } from "../../../Helpers/sort";

export default function Professionals() {
  const [showEdit, setShowEdit] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [state, setState] = React.useState({
    loading: true,
    data: null,
    error: false,
  });

  //sort
  const [sortName, setSortName] = React.useState(false);
  const [sortType, setSortType] = React.useState(false);
  const [sortSpecialized, setSortSpecialized] = React.useState(false);
  const [sortHometown, setSortHometown] = React.useState(false);

  const hometownSort = () => {
    let newData = [...state.data];
    const val = !sortHometown;
    if (val) {
      newData.sort(sortOn("home_town"));
    }
    setFormattedData(newData);
    setSortHometown(val);
  };

  const nameSort = () => {
    let newData = [...state.data];
    const val = !sortName;
    if (val) {
      newData.sort(sortOn("name"));
    }
    setFormattedData(newData);
    setSortName(val);
  };

  const typeSort = () => {
    let newData = [...state.data];
    const val = !sortType;
    if (val) {
      newData.sort(sortOn("pro_type"));
    }
    setFormattedData(newData);
    setSortType(val);
  };

  const specializedSort = () => {
    let newData = [...state.data];
    const val = !sortSpecialized;
    if (val) {
      newData.sort(sortOn("specialized"));
    }
    setFormattedData(newData);
    setSortSpecialized(val);
  };

  const [formattedData, setFormattedData] = React.useState([]);
  const [paginate, setPaginate] = React.useState({ total: 0, page: 1, per_page: 20, total_page: 0 });

  const fetchUsers = async (q = "", page = false, per_page = false) => {
    setState({ ...state, loading: true });
    try {
      const res = await execGetPros(q, page || paginate.page, per_page || paginate.per_page);
      console.log(res);
      setState({ loading: false, data: res.data, error: false });
      setPaginate({ total: res.total, page: res.page, per_page: res.per_page, total_page: res.total_page });
      setFormattedData(res.data);
    } catch (err) {
      console.log(err);
      setState({ loading: false, data: null, error: err });
    }
  };

  React.useEffect(() => {
    fetchUsers();
    //eslint-disable-next-line
  }, []);

  return (
    <section id="contents">
      <EditProfessional
        visible={showEdit}
        data={editData}
        onCancel={(refresh = false) => {
          setShowEdit(false);
          if (refresh === true) fetchUsers();
        }}
      />
      <br />
      <div className="flex jcsb">
        <h1 className="title"> Professionals </h1>
      </div>
      <div className="flex jcsb">
        <div className="res-search">
          <Input.Search
            placeholder="Search.."
            onSearch={(val) => {
              fetchUsers(val, 1);
            }}
            onChange={(e) => {
              const val = e.target.value;
              if (!val || val.length === 0) {
                fetchUsers("", 1);
              }
            }}
            allowClear
          ></Input.Search>
        </div>
        <Pagination
          onChange={(val) => {
            fetchUsers("", val);
          }}
          total={paginate.total}
          current={paginate.page}
          showSizeChanger
          pageSize={paginate.per_page}
          onShowSizeChange={(val, perPage) => {
            fetchUsers("", val, perPage);
          }}
        />
      </div>
      <br />
      {state.loading && <Loading />}
      {state.error && <p style={{ color: "red" }}>{JSON.stringify(state.error)}</p>}
      {state.data && formattedData.length > 0 && (
        <React.Fragment>
          <div className="contents-table">
            <table cellPadding="0" cellSpacing="0">
              <ContentTableHead
                nameSort={nameSort}
                nameSortMode={sortName}
                typeSort={typeSort}
                typeSortMode={sortType}
                specializedSort={specializedSort}
                specializedSortMode={sortSpecialized}
                hometownSort={hometownSort}
                hometownSortMode={sortHometown}
              />
              <tbody>
                {formattedData.map((item, index) => {
                  return (
                    <ContentTableItems
                      refresh={fetchUsers}
                      onEdit={() => {
                        setEditData(item);
                        setShowEdit(true);
                      }}
                      key={item._id}
                      {...item}
                      sn={index + 1}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </section>
  );
}

const ContentTableHead = (props) => {
  return (
    <thead>
      <tr>
        <th style={{ maxWidth: "40px" }}>SN</th>
        <th style={{ cursor: "pointer" }} onClick={() => props.nameSort()}>
          Name
          {props.nameSortMode ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        </th>
        <th style={{ cursor: "pointer" }} onClick={() => props.typeSort()}>
          Type
          {props.typeSortMode ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        </th>
        <th style={{ cursor: "pointer" }} onClick={() => props.specializedSort()}>
          Specialized
          {props.specializedSortMode ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        </th>
        <th style={{ cursor: "pointer" }} onClick={() => props.hometownSort()}>
          Home Town
          {props.hometownSortMode ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        </th>
        <th>Contact</th>
        <th>Status</th>
        <th style={{ maxWidth: "200px" }}>Created At</th>
        <th style={{ maxWidth: "200px" }}>Actions</th>
      </tr>
    </thead>
  );
};

const ContentTableItems = (props) => {
  const onDelete = () => {
    Modal.confirm({
      title: "Are you sure?",
      content: "you want to delete this professional",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await execDeletePros(props._id);
          notifySuccess(res.message);
          props.refresh();
        } catch (err) {
          notifyError(err.message);
        }
      },
    });
  };

  const onStatusChange = async () => {
    const status = props.status === "Pending" ? "Active" : "Pending";
    try {
      const res = await execUpdatePros(props._id, { status });
      notifySuccess(res.message);
      props.refresh();
    } catch (err) {
      notifyError(err.message);
    }

    // Modal.confirm({
    //   title: "Are you sure?",
    //   content: "you want to change status to " + status,
    //   icon: <ExclamationCircleOutlined />,
    //   okText: "Yes",
    //   okType: "danger",
    //   cancelText: "No",
    //   onOk: async () => {
    //     try {
    //       const res = await execUpdatePros(props._id, { status });
    //       notifySuccess(res.message);
    //       props.refresh();
    //     } catch (err) {
    //       notifyError(err.message);
    //     }
    //   },
    // });
  };

  return (
    <tr>
      <td>{props.sn}</td>
      <td>
        {props.prefix} {props.name}
      </td>
      <td>{props.pro_type}</td>
      <td>{props.specialized}</td>
      <td>{props.home_town}</td>
      <td>{props.contact}</td>
      <td>
        <Tag color={getRoleColor(props.status)}>{props.status}</Tag>
        <Button shape="circle" onClick={onStatusChange} icon={<EditOutlined />} size="small" type="primary" />
      </td>
      <td>{props.created_at}</td>
      <td className="actions">
        <div className="flex jcc ci">
          <Button shape="circle" onClick={() => props.onEdit()} icon={<EditOutlined />} size="small" type="primary" />
          <div className="hgap"></div>
          <Button shape="circle" onClick={() => onDelete()} icon={<DeleteOutlined />} size="small" type="danger" />
        </div>
      </td>
    </tr>
  );
};
