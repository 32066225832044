export const sortOnEducation = (property) => {
  return function (a, b) {
    let dA;
    let dB;

    if (a.education && a.education[property]) dA = a.education[property].toLowerCase();
    else dA = "n/a";
    if (b.education && b.education[property]) dB = b.education[property].toLowerCase();
    else dB = "n/a";

    if (dA < dB) {
      return -1;
    } else if (dA > dB) {
      return 1;
    } else {
      return 0;
    }
  };
};

export const sortOnLocation = (property) => {
  return function (a, b) {
    let dA;
    let dB;

    if (a.location && a.location[property]) dA = a.location[property].toLowerCase();
    else dA = "n/a";
    if (b.location && b.location[property]) dB = b.location[property].toLowerCase();
    else dB = "n/a";

    if (dA < dB) {
      return -1;
    } else if (dA > dB) {
      return 1;
    } else {
      return 0;
    }
  };
};

export const sortOnName = () => {
  return function (a, b) {
    if (a.name && b.name) {
      const dA = a.name.toLowerCase();
      const dB = b.name.toLowerCase();
      if (dA < dB) {
        return -1;
      } else if (dA > dB) {
        return 1;
      } else {
        return 0;
      }
    }
    return 1;
  };
};

export const sortOn = (name) => {
  return function (a, b) {
    if (a[name] && b[name]) {
      const dA = a[name].toLowerCase();
      const dB = b[name].toLowerCase();
      if (dA < dB) {
        return -1;
      } else if (dA > dB) {
        return 1;
      } else {
        return 0;
      }
    }
    return 1;
  };
};
