import React from "react";
import "./_addBlog.scss";
import { Input, Button, Form } from "antd";
import { notifyError, notifySuccess } from "../../../Helpers/notification";
import { execFileUpload, execAddBlog } from "../../../api/apiCalls";
import CkEditor from "../../../Components/CKeditor/CKeditor";

export default function AddEvents({ history }) {
  const [fileChange, setFileChange] = React.useState(null);
  const [fileUploaded, setFileUploaded] = React.useState({});
  const [fileUploadLoading, setFileUploadLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [body, setBody] = React.useState("");
  const fileRef = React.createRef();

  const addFile = () => {
    const data = new FormData();
    data.append("file", fileChange);
    data.append("file_for", "BLOG_COVER");
    setFileUploadLoading(true);
    execFileUpload(data)
      .then((res) => {
        setFileUploaded({ id: res.id, url: res.url });
        setFileUploadLoading(false);
        notifySuccess(res.message);
      })
      .catch((err) => {
        setFileUploadLoading(false);
        notifyError(err.message);
      });
  };

  React.useEffect(() => {
    if (fileChange !== null) {
      addFile();
    }
    //eslint-disable-next-line
  }, [fileChange]);

  const addEvent = async (value) => {
    let payload;
    if (!fileUploaded || !fileUploaded.id) {
      payload = { ...value, body };
    } else {
      payload = { ...value, body, cover_photo: fileUploaded.id };
    }
    setLoading(true);
    try {
      const res = await execAddBlog(payload);
      notifySuccess(res.message);
      history.push("/admin/blogs");
      setLoading(false);
    } catch (err) {
      notifyError(err.message);
      setLoading(false);
    }
    //TODO: submit to add blog api
  };

  return (
    <section id="contents">
      <br />

      <h1 className="title"> Add Blog </h1>
      <input
        type="file"
        onChange={(e) => {
          setFileChange(e.target.files[0]);
        }}
        ref={fileRef}
        style={{ display: "none" }}
      />

      <br />

      <div className="inputs">
        <Form onFinish={addEvent}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 2fr",
              gridGap: 15,
            }}
          >
            <aside>
              <div
                className="cover"
                style={{
                  background: "#fff",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  height: 150,
                  boxShadow: "var(--shadow)",
                  borderRadius: 4,
                  overflow: "hidden",
                }}
              >
                {fileUploaded.id && (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={fileUploaded.url}
                    alt=""
                  />
                )}
              </div>
              <br />
              <Button
                onClick={() => {
                  setFileUploaded({});
                }}
                type="primary"
              >
                Clear Cover
              </Button>
            </aside>
            <aside>
              <Form.Item
                label="Title"
                name="title"
                className="full"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                  },
                ]}
              >
                <Input placeholder="eg. this is a blog" />
              </Form.Item>
              <div className="gap"></div>
              <Button
                type="primary"
                loading={fileUploadLoading}
                onClick={() => {
                  fileRef.current.click();
                }}
              >
                Upload Cover Photo
              </Button>

              <div className="hgap"></div>
              <Button htmlType="submit" loading={loading} type="primary">
                Add Blog
              </Button>
            </aside>
          </div>
        </Form>
        <br />
      </div>
      <div className="add-editor">
        <CkEditor
          onChange={(value) => {
            setBody(value);
          }}
        />
      </div>
    </section>
  );
}
