import { notification } from "antd";

export const notifyError = (msg, placement = "bottomRight") => {
  notification.error({
    message: msg,
    duration: 0.5,
    placement,
  });
};

export const notifySuccess = (msg, placement = "bottomRight") => {
  notification.success({
    message: msg,
    duration: 0.5,
    placement,
  });
};
