import React from "react";
import { Modal, Input, Form, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { notifySuccess, notifyError } from "../../../Helpers/notification";
import { execUpdateBusiness } from "../../../api/apiCalls";
const businessList = ["Student Associations", "Non Profits"];

export default function EditBusiness(props) {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const onEdit = async (val) => {
    setLoading(true);
    try {
      const res = await execUpdateBusiness(props.data._id, val);
      setLoading(false);
      notifySuccess(res.message);
      props.onCancel(true);
    } catch (err) {
      setLoading(false);
      notifyError(err.message);
    }
  };

  React.useEffect(() => {
    form.setFieldsValue({ ...props.data });
    // eslint-disable-next-line
  }, [props.data]);

  return (
    <div>
      <Modal
        title="Edit Organization"
        visible={props.visible}
        maskClosable={false}
        centered
        onCancel={() => {
          props.onCancel();
        }}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{}}
      >
        <Form form={form} onFinish={onEdit}>
          <div className="form-field-wrapper">
            <Form.Item
              className="full"
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
            >
              <Input placeholder="eg. John Doe" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Status is required",
                },
              ]}
              className="full"
              label="Status"
              name="status"
            >
              <Select placeholder="Select one">
                <Select.Option value="Active">Active</Select.Option>
                <Select.Option value="Pending">Pending</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              className="full"
              label="Organization Type"
              name="business_type"
              rules={[
                {
                  required: true,
                  message: "Organization Type is required",
                },
              ]}
            >
              <Select placeholder="Select one">
                {businessList.map((item) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="full"
              label="City Zip Code"
              name="zip"
              rules={[
                {
                  required: true,
                  message: "Zip Code is required",
                },
                () => ({
                  validator(_, value) {
                    if (value && value.length === 5) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Zip Code must be of exact 5 digit");
                  },
                }),
              ]}
            >
              <Input type="number" placeholder="eg. 00977" />
            </Form.Item>
          </div>
          <Form.Item className="full" label="Contact" name="contact">
            <Input placeholder="eg. +10000000000" />
          </Form.Item>
          <Form.Item className="full" label="Description" name="description">
            <TextArea placeholder="eg. something about yourself or your profession" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
