import React from "react";
import { Button, Tooltip } from "antd";
import { getDetailsFromLocalStorage } from "../../../Helpers/helper";
import { MenuOutlined, LogoutOutlined } from "@ant-design/icons";
import configs from "../../../config";

export default function TopNavBar(props) {
  const doLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("login_details");
    props.history.push("/login");
  };

  return (
    <nav>
      <div className="wrap">
        <div className="flex jcsb ci">
          <div className="left">
            <div className="flex ci">
              <React.Fragment>
                <span
                  style={{ padding: "4px", cursor: "pointer" }}
                  onClick={() => {
                    props.setSliderCollapsed(!props.sliderCollapsed);
                  }}
                >
                  <MenuOutlined />
                </span>
                <div className="hgap"></div>
              </React.Fragment>
              <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
                {configs.SYSTEM_NAME}, {getDetailsFromLocalStorage().name}
              </h2>
            </div>
          </div>
          <div className="right">
            <Tooltip title="Logout">
              <Button
                onClick={() => {
                  doLogout();
                }}
                shape="circle"
              >
                <LogoutOutlined />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </nav>
  );
}
