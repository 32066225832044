import React from "react";
import "./_content.scss";
import { Button, Tag, Modal, Pagination, Input } from "antd";
import {
  EyeOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import Loading from "../../../Components/Loading";
import { execGetUsers, execDeleteUser } from "../../../api/apiCalls";
import { Link } from "react-router-dom";
import { getRoleColor } from "../../../config";
import { notifySuccess, notifyError } from "../../../Helpers/notification";
import { sortOnName, sortOnEducation, sortOnLocation } from "../../../Helpers/sort";

export default function Users() {
  const [state, setState] = React.useState({
    loading: true,
    data: null,
    error: false,
  });
  const [formattedData, setFormattedData] = React.useState(null);
  const [paginate, setPaginate] = React.useState({ total: 0, page: 1, per_page: 20, total_page: 0 });

  //sort
  const [sortName, setSortName] = React.useState(false);
  const [sortUni, setSortUni] = React.useState(false);
  const [sortCity, setSortCity] = React.useState(false);

  const uniSort = () => {
    let newData = [...state.data];
    const val = !sortUni;
    if (val) {
      newData.sort(sortOnEducation("university"));
    }
    setFormattedData(newData);
    setSortUni(val);
  };

  const citySort = () => {
    let newData = [...state.data];
    const val = !sortCity;
    if (val) {
      newData.sort(sortOnLocation("city"));
    }
    setFormattedData(newData);
    setSortCity(val);
  };

  const nameSort = () => {
    let newData = [...state.data];
    const val = !sortName;
    if (val) {
      newData.sort(sortOnName());
    }
    setFormattedData(newData);
    setSortName(val);
  };

  const fetchUsers = async (q = "", page = false, per_page = false) => {
    setState({ ...state, data: null, loading: true });
    try {
      const res = await execGetUsers(q, page || paginate.page, per_page || paginate.per_page);
      setPaginate({ total: res.total, page: res.page, per_page: res.per_page, total_page: res.total_page });
      setState({ loading: false, data: res.data, error: false });
      setFormattedData(res.data);
    } catch (err) {
      setState({ loading: false, data: null, error: err });
    }
  };

  React.useEffect(() => {
    fetchUsers();
    //eslint-disable-next-line
  }, []);

  return (
    <section id="contents">
      <br />
      <h1 className="title"> Users </h1>
      <br />
      <div className="flex jcsb ci">
        <div className="res-search">
          <Input.Search
            placeholder="Search Education"
            onSearch={(val) => {
              fetchUsers(val);
            }}
            allowClear
            onChange={(e) => {
              if (!e.target.value) fetchUsers();
            }}
          ></Input.Search>
        </div>
        <Pagination
          onChange={(val) => {
            fetchUsers("", val);
          }}
          total={paginate.total}
          current={paginate.page}
          showSizeChanger
          pageSize={paginate.per_page}
          onShowSizeChange={(val, perPage) => {
            fetchUsers("", val, perPage);
          }}
        />
      </div>
      <br />
      {state.loading && <Loading />}
      {state.error && <p style={{ color: "red" }}>{JSON.stringify(state.error)}</p>}
      {state.data && formattedData && formattedData.length > 0 && (
        <React.Fragment>
          <div className="contents-table">
            <table cellPadding="0" cellSpacing="0">
              <ContentTableHead
                nameSort={nameSort}
                nameSortMode={sortName}
                uniSort={uniSort}
                uniSortMode={sortUni}
                citySort={citySort}
                citySortMode={sortCity}
              />
              <tbody>
                {formattedData.map((item, index) => {
                  return <ContentTableItems refresh={fetchUsers} key={item.id} {...item} sn={index + 1} />;
                })}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </section>
  );
}

const ContentTableHead = (props) => {
  return (
    <thead>
      <tr>
        <th style={{ maxWidth: "40px" }}>SN</th>
        <th style={{ cursor: "pointer" }} onClick={() => props.nameSort()}>
          Name
          {props.nameSortMode ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        </th>
        <th>Email</th>
        <th>Role</th>
        <th style={{ cursor: "pointer" }} onClick={() => props.citySort()}>
          City
          {props.citySortMode ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        </th>
        <th style={{ cursor: "pointer" }} onClick={() => props.uniSort()}>
          University
          {props.uniSortMode ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        </th>
        <th>Gender</th>
        <th style={{ maxWidth: "200px" }}>Created At</th>
        <th style={{ maxWidth: "200px" }}>Actions</th>
      </tr>
    </thead>
  );
};

const ContentTableItems = (props) => {
  const onDelete = () => {
    Modal.confirm({
      title: "Are you sure?",
      content: "you want to delete this user permanently",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await execDeleteUser(props._id);
          notifySuccess(res.message);
          props.refresh();
        } catch (err) {
          notifyError(err.message);
        }
      },
    });
  };

  return (
    <tr>
      <td>{props.sn}</td>
      <td>
        <div>{props.name}</div>
      </td>
      <td>{props.email}</td>
      <td>
        <Tag color={getRoleColor(props.role)}>{props.role}</Tag>
      </td>
      <td>{(props.location && props.location.city) || "n/a"}</td>
      <td>{(props.education && props.education.university) || "n/a"}</td>
      <td>{props.gender}</td>
      <td>{props.created_at}</td>
      <td className="actions">
        <Link to={"/admin/user/" + props._id}>
          <Button shape="circle" icon={<EyeOutlined />} size="small" type="primary" />
        </Link>
        <div className="hgap"></div>
        <Button shape="circle" icon={<DeleteOutlined />} onClick={() => onDelete()} size="small" type="danger" />
      </td>
    </tr>
  );
};
