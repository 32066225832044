import Axios from "axios";
import { notification } from "antd";

export const base_url = "https://mangonepal.com/v1";
// export const base_url = "http://localhost:3001/v1";

export const axiosInstance = Axios.create({
  baseURL: base_url,
  timeout: 10000,
});

//add token to all request
axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("admin_token");
  config.headers.Authorization = "Bearer " + token;
  return config;
});

axiosInstance.interceptors.response.use(
  //handle on success
  function (response) {
    return response.data || null;
  },

  //handle on error
  function (error) {
    if (error.response && error.response.data) {
      if (error.response.data.message) {
        // notification.error({
        //   message: error.response.data.message,
        //   duration: 5
        // });
      }
    } else {
      notification.error({
        message: "Some unusual error occured, please try again",
        duration: 5,
      });
      return Promise.reject({
        error: "Some unusual error occured, please try again",
      });
    }
    return Promise.reject(error.response.data);
  }
);
