const majorList = [
  "Accounting",
  "Actuarial Science",
  "Advertising",
  "Agriculture",
  "Agricultural and Biological Engineering",
  "Agricultural Business Management",
  "Agriculture Economics",
  "Animal Bioscience",
  "Animal Sciences",
  "Anthropology",
  "Applied Mathematics",
  "Archaeology",
  "Architectural Engineering",
  "Architecture",
  "Art History",
  "Studio Art",
  "Art Education",
  "Biobehavioral Health",
  "Biochemistry",
  "Bioengineering",
  "Biology",
  "Biophysics",
  "Biotechnology",
  "Business Administration and Management",
  "Business Logistics",
  "Chemical Engineering",
  "Chemistry",
  "Children",
  "Civil Engineering",
  "Computer Engineering",
  "Computer Science",
  "Crime, Law, and Justice",
  "Dance",
  "Data Science/Analytics",
  "Earth Sciences",
  "Economics",
  "Electrical Engineering",
  "Elementary and Kindergarten Education",
  "Engineering Science",
  "English",
  "Environmental Systems Engineering",
  "Environmental Sciences",
  "Environmental Resource Management",
  "Film and Video",
  "Finance",
  "Food Science",
  "Forest Science",
  "Forest Technology",
  "General Science",
  "Geography",
  "Geosciences",
  "Graphic Design and Photography",
  "Health and Physical Education",
  "Health Policy and Administration",
  "History",
  "Horticulture",
  "Hotel, Restaurant, and Institutional Management",
  "Human Development and Family Studies",
  "Individual and Family Studies",
  "Industrial Engineering",
  "Information Sciences and Technology",
  "Journalism",
  "Kinesiology",
  "Landscape Architecture",
  "Law Enforcement and Correction",
  "Marine Biology",
  "Marketing",
  "Mathematics",
  "Mechanical Engineering",
  "Media Studies",
  "Meteorology",
  "Microbiology",
  "Mineral Economics",
  "Modern Languages",
  "Music Education",
  "Nuclear Engineering",
  "Nursing",
  "Nutrition",
  "Philosophy",
  "Physics",
  "Physiology",
  "Political Science",
  "Pre-medicine",
  "Psychology",
  "Public administration",
  "Public Relations",
  "Real Estate",
  "Recreation and Parks",
  "Rehabilitation Services",
  "Religious Studies",
  "Secondary Education",
  "Sociology",
  "Social Work",
  "Special Education",
  "Speech Communication",
  "Speech Pathology and Audiology/Communication Disorder",
  "Statistics",
  "Telecommunications",
  "Theater",
  "Wildlife and Fishery Science",
  "Wildlife Technology",
  "Women's Studies",
];

export default majorList;
