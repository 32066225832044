import React from "react";
import { Modal, Input, Form, AutoComplete } from "antd";
import { execProfileUpdate } from "../../../api/apiCalls";
import { notifySuccess, notifyError } from "../../../Helpers/notification";
const districtList = ["Achham", "Argakhachi", "Baglung", "Baitadi", "Bajhang", "Bajura", "Banke", "Bara", "Bardiya", "Bhaktapur", "Bhojpur", "Chitwan", "Dadeldhura", "Dailekh", "Dang", "Darchula", "Dhading", "Dhankuta", "Dhanusha", "Dolkha", "Dolpa", "Doti", "Gorkha", "Gulmi", "Humla", "Illam", "Jajarkot", "Jhapa", "Jumla", "Kailali", "Kalikot", "Kanchanpur", "Kapilvastu", "Kaski", "Kathmandu", "Kavrepalanchowk", "Khotang", "Lalitpur", "Lamjung", "Mahottari", "Makwanpur", "Manang", "Morang", "Mugu", "Mustang", "Myagdi", "Nawalparasi_E", "Nawalparasi_W", "Nuwakot", "Okhaldhunga", "Palpa", "Panchthar", "Parbat", "Parsa", "Pyuthan", "Ramechhap", "Rasuwa", "Rautahat", "Rolpa", "Rukum_E", "Rukum_W", "Rupendehi", "Salyan", "Sankhuwasabha", "Saptari", "Sarlahi", "Sindhuli", "Sindhupalchowk", "Siraha", "Solukhumbu", "Sunsari", "Surkhet", "Syangja", "Tanahun", "Taplejung", "Terhathum", "Udayapur"];

export default function EditLocation(props) {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const onEdit = async (val) => {
    const payload = {
      home_town: val.home_town,
      location: {
        zip: val.zip,
        city: val.city,
        state: val.state,
      },
    };
    setLoading(true);
    try {
      const res = await execProfileUpdate(props.data._id, payload);
      setLoading(false);
      notifySuccess(res.message);
      props.onCancel(true);
      form.resetFields();
    } catch (err) {
      setLoading(false);
      notifyError(err.message);
    }
  };

  React.useEffect(() => {
    if (!props.data) return;
    form.setFieldsValue({ ...props.data, ...props.data.location });
    // eslint-disable-next-line
  }, [props.data]);

  return (
    <div>
      <Modal
        title="Edit Location"
        visible={props.visible}
        maskClosable={false}
        centered
        onCancel={() => {
          props.onCancel();
        }}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{}}
      >
        <Form form={form} onFinish={onEdit}>
          <Form.Item className="full" label="Home Town" name="home_town">
            <AutoComplete placeholder="Select Hometown" filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
              {districtList.map((item, index) => (
                <AutoComplete.Option key={index} value={item}>
                  {item}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>
          <Form.Item
            className="full"
            label="City Zip Code"
            name="zip"
            rules={[
              {
                required: true,
                message: "Zip Code is required",
              },
              () => ({
                validator(_, value) {
                  if (value && value.length === 5) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Zip Code must be of exact 5 digit");
                },
              }),
            ]}
          >
            <Input type="number" placeholder="eg. 00977" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
