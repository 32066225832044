import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import React from "react";
import { execFileUpload } from "../../api/apiCalls";

export default function CkEditor({ onChange, body }) {
  return (
    <React.Fragment>
      <div id="toolbar-container"></div>
      <CKEditor
        editor={DecoupledEditor}
        data={body || ""}
        onInit={(editor) => {
          const toolbarContainer = document.querySelector("#toolbar-container");
          toolbarContainer.appendChild(editor.ui.view.toolbar.element);
          window.editor = editor;
          editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
            return new MyUploadAdapter(loader);
          };
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          if (onChange) onChange(data);
        }}
      />
    </React.Fragment>
  );
}

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
    // this.url = baseUrl + "/upload";
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const data = new FormData();
          data.append("file", file);
          data.append("file_for", "BLOG_BODY");

          execFileUpload(data)
            .then((res) => {
              console.log(res.url);
              resolve({ default: res.url });
            })
            .catch((err) => {
              reject(err.message);
            });
        })
    );
  }

  // Aborts the upload process.
  abort() {
    return "";
  }
}
