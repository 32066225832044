import React from "react";
import "./_dashboard.scss";
import DashboardSlider from "./DashboardSlider";
import TopNavBar from "./TopNavBar";
import { Switch, Route } from "react-router-dom";
import Users from "../Users/Users";
import Blogs from "../Blogs/Blogs";
import UserDetails from "../UserDetails/UserDetails";
import AddBlog from "../AddBlog/AddBlog";
import EditBlog from "../EditBlog/EditBlog";
import Professionals from "../Professionals/Professionals";
import Business from "../Business/Business";
import Resource from "../Resource/Resource";
import Testimonial from "../Testimonial/Testimonial";

export default function Dashboard(props) {
  const [sliderCollapsed, setSliderCollapsed] = React.useState(false);

  return (
    <section id="dashboard">
      <div className="flex">
        <div className="dashboard-left">
          <DashboardSlider sliderCollapsed={sliderCollapsed} setSliderCollapsed={setSliderCollapsed} />
        </div>
        <div className="dashboard-right">
          <TopNavBar history={props.history} sliderCollapsed={sliderCollapsed} setSliderCollapsed={setSliderCollapsed} />
          <div className="wrap">
            <Switch>
              <Route path={props.match.url + "/"} exact component={Users} />
              <Route path={props.match.url + "/blogs"} exact component={Blogs} />
              <Route path={props.match.url + "/professionals"} exact component={Professionals} />
              <Route path={props.match.url + "/business"} exact component={Business} />
              <Route path={props.match.url + "/resource"} exact component={Resource} />
              <Route path={props.match.url + "/add_blog"} exact component={AddBlog} />
              <Route path={props.match.url + "/user/:id"} exact component={UserDetails} />
              <Route path={props.match.url + "/edit_blog/:slug"} exact component={EditBlog} />
              <Route path={props.match.url + "/testimonial"} exact component={Testimonial} />
            </Switch>
          </div>
        </div>
      </div>
    </section>
  );
}
